/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface Domainv1Label
 */
export interface Domainv1Label {
    /**
     * 
     * @type {string}
     * @memberof Domainv1Label
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof Domainv1Label
     */
    confidence?: number;
}

/**
 * Check if a given object implements the Domainv1Label interface.
 */
export function instanceOfDomainv1Label(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Domainv1LabelFromJSON(json: any): Domainv1Label {
    return Domainv1LabelFromJSONTyped(json, false);
}

export function Domainv1LabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Domainv1Label {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'confidence': !exists(json, 'confidence') ? undefined : json['confidence'],
    };
}

export function Domainv1LabelToJSON(value?: Domainv1Label | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'confidence': value.confidence,
    };
}

