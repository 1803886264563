/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1Action } from './V1Action';
import {
    V1ActionFromJSON,
    V1ActionFromJSONTyped,
    V1ActionToJSON,
} from './V1Action';
import type { V1CommerceCouponUserStatus } from './V1CommerceCouponUserStatus';
import {
    V1CommerceCouponUserStatusFromJSON,
    V1CommerceCouponUserStatusFromJSONTyped,
    V1CommerceCouponUserStatusToJSON,
} from './V1CommerceCouponUserStatus';
import type { V1ViewTypeCommerceCouponBusinessProfile } from './V1ViewTypeCommerceCouponBusinessProfile';
import {
    V1ViewTypeCommerceCouponBusinessProfileFromJSON,
    V1ViewTypeCommerceCouponBusinessProfileFromJSONTyped,
    V1ViewTypeCommerceCouponBusinessProfileToJSON,
} from './V1ViewTypeCommerceCouponBusinessProfile';

/**
 * 
 * @export
 * @interface V1ViewTypeCommerceCoupon
 */
export interface V1ViewTypeCommerceCoupon {
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeCommerceCoupon
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeCommerceCoupon
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeCommerceCoupon
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeCommerceCoupon
     */
    expiredAtDisplayText: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeCommerceCoupon
     */
    remainingCouponCountDisplayText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ViewTypeCommerceCoupon
     */
    isBrandCoupon: boolean;
    /**
     * 
     * @type {V1CommerceCouponUserStatus}
     * @memberof V1ViewTypeCommerceCoupon
     */
    userStatus: V1CommerceCouponUserStatus;
    /**
     * 
     * @type {V1ViewTypeCommerceCouponBusinessProfile}
     * @memberof V1ViewTypeCommerceCoupon
     */
    businessProfile: V1ViewTypeCommerceCouponBusinessProfile;
    /**
     * 
     * @type {Array<V1Action>}
     * @memberof V1ViewTypeCommerceCoupon
     */
    actions: Array<V1Action>;
}

/**
 * Check if a given object implements the V1ViewTypeCommerceCoupon interface.
 */
export function instanceOfV1ViewTypeCommerceCoupon(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "imageUrl" in value;
    isInstance = isInstance && "expiredAtDisplayText" in value;
    isInstance = isInstance && "isBrandCoupon" in value;
    isInstance = isInstance && "userStatus" in value;
    isInstance = isInstance && "businessProfile" in value;
    isInstance = isInstance && "actions" in value;

    return isInstance;
}

export function V1ViewTypeCommerceCouponFromJSON(json: any): V1ViewTypeCommerceCoupon {
    return V1ViewTypeCommerceCouponFromJSONTyped(json, false);
}

export function V1ViewTypeCommerceCouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ViewTypeCommerceCoupon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'imageUrl': json['image_url'],
        'expiredAtDisplayText': json['expired_at_display_text'],
        'remainingCouponCountDisplayText': !exists(json, 'remaining_coupon_count_display_text') ? undefined : json['remaining_coupon_count_display_text'],
        'isBrandCoupon': json['is_brand_coupon'],
        'userStatus': V1CommerceCouponUserStatusFromJSON(json['user_status']),
        'businessProfile': V1ViewTypeCommerceCouponBusinessProfileFromJSON(json['business_profile']),
        'actions': ((json['actions'] as Array<any>).map(V1ActionFromJSON)),
    };
}

export function V1ViewTypeCommerceCouponToJSON(value?: V1ViewTypeCommerceCoupon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'image_url': value.imageUrl,
        'expired_at_display_text': value.expiredAtDisplayText,
        'remaining_coupon_count_display_text': value.remainingCouponCountDisplayText,
        'is_brand_coupon': value.isBrandCoupon,
        'user_status': V1CommerceCouponUserStatusToJSON(value.userStatus),
        'business_profile': V1ViewTypeCommerceCouponBusinessProfileToJSON(value.businessProfile),
        'actions': ((value.actions as Array<any>).map(V1ActionToJSON)),
    };
}

