/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1FeedViewTypeType } from './V1FeedViewTypeType';
import {
    V1FeedViewTypeTypeFromJSON,
    V1FeedViewTypeTypeFromJSONTyped,
    V1FeedViewTypeTypeToJSON,
} from './V1FeedViewTypeType';
import type { V1ViewTypeActionableBoxButtonGroup } from './V1ViewTypeActionableBoxButtonGroup';
import {
    V1ViewTypeActionableBoxButtonGroupFromJSON,
    V1ViewTypeActionableBoxButtonGroupFromJSONTyped,
    V1ViewTypeActionableBoxButtonGroupToJSON,
} from './V1ViewTypeActionableBoxButtonGroup';
import type { V1ViewTypeActionableCallout } from './V1ViewTypeActionableCallout';
import {
    V1ViewTypeActionableCalloutFromJSON,
    V1ViewTypeActionableCalloutFromJSONTyped,
    V1ViewTypeActionableCalloutToJSON,
} from './V1ViewTypeActionableCallout';
import type { V1ViewTypeSectionLocalBusinessStory } from './V1ViewTypeSectionLocalBusinessStory';
import {
    V1ViewTypeSectionLocalBusinessStoryFromJSON,
    V1ViewTypeSectionLocalBusinessStoryFromJSONTyped,
    V1ViewTypeSectionLocalBusinessStoryToJSON,
} from './V1ViewTypeSectionLocalBusinessStory';
import type { V1ViewTypeSectionPublicInstitutionPost } from './V1ViewTypeSectionPublicInstitutionPost';
import {
    V1ViewTypeSectionPublicInstitutionPostFromJSON,
    V1ViewTypeSectionPublicInstitutionPostFromJSONTyped,
    V1ViewTypeSectionPublicInstitutionPostToJSON,
} from './V1ViewTypeSectionPublicInstitutionPost';
import type { V1ViewTypeSectionRecommendCoupon } from './V1ViewTypeSectionRecommendCoupon';
import {
    V1ViewTypeSectionRecommendCouponFromJSON,
    V1ViewTypeSectionRecommendCouponFromJSONTyped,
    V1ViewTypeSectionRecommendCouponToJSON,
} from './V1ViewTypeSectionRecommendCoupon';
import type { V1ViewTypeSectionRecommendPost } from './V1ViewTypeSectionRecommendPost';
import {
    V1ViewTypeSectionRecommendPostFromJSON,
    V1ViewTypeSectionRecommendPostFromJSONTyped,
    V1ViewTypeSectionRecommendPostToJSON,
} from './V1ViewTypeSectionRecommendPost';

/**
 * 
 * @export
 * @interface V1FeedViewType
 */
export interface V1FeedViewType {
    /**
     * 
     * @type {V1FeedViewTypeType}
     * @memberof V1FeedViewType
     */
    type: V1FeedViewTypeType;
    /**
     * 
     * @type {V1ViewTypeSectionLocalBusinessStory}
     * @memberof V1FeedViewType
     */
    sectionLocalBusinessStory?: V1ViewTypeSectionLocalBusinessStory;
    /**
     * 
     * @type {V1ViewTypeSectionRecommendPost}
     * @memberof V1FeedViewType
     */
    sectionRecommendPost?: V1ViewTypeSectionRecommendPost;
    /**
     * 
     * @type {V1ViewTypeSectionRecommendCoupon}
     * @memberof V1FeedViewType
     */
    sectionRecommendCoupon?: V1ViewTypeSectionRecommendCoupon;
    /**
     * 
     * @type {V1ViewTypeActionableCallout}
     * @memberof V1FeedViewType
     */
    actionableCallout?: V1ViewTypeActionableCallout;
    /**
     * 
     * @type {V1ViewTypeSectionPublicInstitutionPost}
     * @memberof V1FeedViewType
     */
    sectionPublicInstitutionPost?: V1ViewTypeSectionPublicInstitutionPost;
    /**
     * 
     * @type {V1ViewTypeActionableBoxButtonGroup}
     * @memberof V1FeedViewType
     */
    actionableBoxButtonGroup?: V1ViewTypeActionableBoxButtonGroup;
}

/**
 * Check if a given object implements the V1FeedViewType interface.
 */
export function instanceOfV1FeedViewType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function V1FeedViewTypeFromJSON(json: any): V1FeedViewType {
    return V1FeedViewTypeFromJSONTyped(json, false);
}

export function V1FeedViewTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1FeedViewType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': V1FeedViewTypeTypeFromJSON(json['type']),
        'sectionLocalBusinessStory': !exists(json, 'section_local_business_story') ? undefined : V1ViewTypeSectionLocalBusinessStoryFromJSON(json['section_local_business_story']),
        'sectionRecommendPost': !exists(json, 'section_recommend_post') ? undefined : V1ViewTypeSectionRecommendPostFromJSON(json['section_recommend_post']),
        'sectionRecommendCoupon': !exists(json, 'section_recommend_coupon') ? undefined : V1ViewTypeSectionRecommendCouponFromJSON(json['section_recommend_coupon']),
        'actionableCallout': !exists(json, 'actionable_callout') ? undefined : V1ViewTypeActionableCalloutFromJSON(json['actionable_callout']),
        'sectionPublicInstitutionPost': !exists(json, 'section_public_institution_post') ? undefined : V1ViewTypeSectionPublicInstitutionPostFromJSON(json['section_public_institution_post']),
        'actionableBoxButtonGroup': !exists(json, 'actionable_box_button_group') ? undefined : V1ViewTypeActionableBoxButtonGroupFromJSON(json['actionable_box_button_group']),
    };
}

export function V1FeedViewTypeToJSON(value?: V1FeedViewType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': V1FeedViewTypeTypeToJSON(value.type),
        'section_local_business_story': V1ViewTypeSectionLocalBusinessStoryToJSON(value.sectionLocalBusinessStory),
        'section_recommend_post': V1ViewTypeSectionRecommendPostToJSON(value.sectionRecommendPost),
        'section_recommend_coupon': V1ViewTypeSectionRecommendCouponToJSON(value.sectionRecommendCoupon),
        'actionable_callout': V1ViewTypeActionableCalloutToJSON(value.actionableCallout),
        'section_public_institution_post': V1ViewTypeSectionPublicInstitutionPostToJSON(value.sectionPublicInstitutionPost),
        'actionable_box_button_group': V1ViewTypeActionableBoxButtonGroupToJSON(value.actionableBoxButtonGroup),
    };
}

