/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1Action } from './V1Action';
import {
    V1ActionFromJSON,
    V1ActionFromJSONTyped,
    V1ActionToJSON,
} from './V1Action';
import type { V1ViewTypeBusinessPostBusinessProfile } from './V1ViewTypeBusinessPostBusinessProfile';
import {
    V1ViewTypeBusinessPostBusinessProfileFromJSON,
    V1ViewTypeBusinessPostBusinessProfileFromJSONTyped,
    V1ViewTypeBusinessPostBusinessProfileToJSON,
} from './V1ViewTypeBusinessPostBusinessProfile';

/**
 * 
 * @export
 * @interface V1ViewTypeBusinessPost
 */
export interface V1ViewTypeBusinessPost {
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeBusinessPost
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeBusinessPost
     */
    thumbnailImageUrl?: string;
    /**
     * 
     * @type {V1ViewTypeBusinessPostBusinessProfile}
     * @memberof V1ViewTypeBusinessPost
     */
    businessProfile: V1ViewTypeBusinessPostBusinessProfile;
    /**
     * 
     * @type {Array<V1Action>}
     * @memberof V1ViewTypeBusinessPost
     */
    actions: Array<V1Action>;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeBusinessPost
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof V1ViewTypeBusinessPost
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeBusinessPost
     */
    walkingDuration?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ViewTypeBusinessPost
     */
    imageUrls: Array<string>;
}

/**
 * Check if a given object implements the V1ViewTypeBusinessPost interface.
 */
export function instanceOfV1ViewTypeBusinessPost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "businessProfile" in value;
    isInstance = isInstance && "actions" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "imageUrls" in value;

    return isInstance;
}

export function V1ViewTypeBusinessPostFromJSON(json: any): V1ViewTypeBusinessPost {
    return V1ViewTypeBusinessPostFromJSONTyped(json, false);
}

export function V1ViewTypeBusinessPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ViewTypeBusinessPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'thumbnailImageUrl': !exists(json, 'thumbnail_image_url') ? undefined : json['thumbnail_image_url'],
        'businessProfile': V1ViewTypeBusinessPostBusinessProfileFromJSON(json['business_profile']),
        'actions': ((json['actions'] as Array<any>).map(V1ActionFromJSON)),
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'walkingDuration': !exists(json, 'walking_duration') ? undefined : json['walking_duration'],
        'imageUrls': json['image_urls'],
    };
}

export function V1ViewTypeBusinessPostToJSON(value?: V1ViewTypeBusinessPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'thumbnail_image_url': value.thumbnailImageUrl,
        'business_profile': V1ViewTypeBusinessPostBusinessProfileToJSON(value.businessProfile),
        'actions': ((value.actions as Array<any>).map(V1ActionToJSON)),
        'id': value.id,
        'created_at': (value.createdAt.toISOString()),
        'walking_duration': value.walkingDuration,
        'image_urls': value.imageUrls,
    };
}

