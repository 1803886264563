// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface PlaceDocumentMeta
 */
export interface PlaceDocumentMeta {
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentMeta
     */
    docId: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentMeta
     */
    cursor: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentMeta
     */
    collectionName: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentMeta
     */
    collectionType: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentMeta
     */
    explanationJsonStr?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentMeta
     */
    termVectorJsonStr?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlaceDocumentMeta
     */
    matchedQueries?: Array<string>;
}

/**
 * Check if a given object implements the PlaceDocumentMeta interface.
 */
export function instanceOfPlaceDocumentMeta(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "docId" in value;
    isInstance = isInstance && "cursor" in value;
    isInstance = isInstance && "collectionName" in value;
    isInstance = isInstance && "collectionType" in value;

    return isInstance;
}

export function PlaceDocumentMetaFromJSON(json: any): PlaceDocumentMeta {
    return PlaceDocumentMetaFromJSONTyped(json, false);
}

export function PlaceDocumentMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceDocumentMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'docId': json['docId'],
        'cursor': json['cursor'],
        'collectionName': json['collection_name'],
        'collectionType': json['collectionType'],
        'explanationJsonStr': !exists(json, 'explanationJsonStr') ? undefined : json['explanationJsonStr'],
        'termVectorJsonStr': !exists(json, 'termVectorJsonStr') ? undefined : json['termVectorJsonStr'],
        'matchedQueries': !exists(json, 'matchedQueries') ? undefined : json['matchedQueries'],
    };
}

export function PlaceDocumentMetaToJSON(value?: PlaceDocumentMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'docId': value.docId,
        'cursor': value.cursor,
        'collectionName': value.collectionName,
        'collectionType': value.collectionType,
        'explanationJsonStr': value.explanationJsonStr,
        'termVectorJsonStr': value.termVectorJsonStr,
        'matchedQueries': value.matchedQueries,
    };
}

