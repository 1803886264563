/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { Searchdomainv1BusinessDay } from './Searchdomainv1BusinessDay';
import {
    Searchdomainv1BusinessDayFromJSON,
    Searchdomainv1BusinessDayFromJSONTyped,
    Searchdomainv1BusinessDayToJSON,
} from './Searchdomainv1BusinessDay';

/**
 * 
 * @export
 * @interface Searchdomainv1BusinessDays
 */
export interface Searchdomainv1BusinessDays {
    /**
     * 
     * @type {Searchdomainv1BusinessDay}
     * @memberof Searchdomainv1BusinessDays
     */
    monday: Searchdomainv1BusinessDay;
    /**
     * 
     * @type {Searchdomainv1BusinessDay}
     * @memberof Searchdomainv1BusinessDays
     */
    tuesday: Searchdomainv1BusinessDay;
    /**
     * 
     * @type {Searchdomainv1BusinessDay}
     * @memberof Searchdomainv1BusinessDays
     */
    wednesday: Searchdomainv1BusinessDay;
    /**
     * 
     * @type {Searchdomainv1BusinessDay}
     * @memberof Searchdomainv1BusinessDays
     */
    thursday: Searchdomainv1BusinessDay;
    /**
     * 
     * @type {Searchdomainv1BusinessDay}
     * @memberof Searchdomainv1BusinessDays
     */
    friday: Searchdomainv1BusinessDay;
    /**
     * 
     * @type {Searchdomainv1BusinessDay}
     * @memberof Searchdomainv1BusinessDays
     */
    saturday: Searchdomainv1BusinessDay;
    /**
     * 
     * @type {Searchdomainv1BusinessDay}
     * @memberof Searchdomainv1BusinessDays
     */
    sunday: Searchdomainv1BusinessDay;
}

/**
 * Check if a given object implements the Searchdomainv1BusinessDays interface.
 */
export function instanceOfSearchdomainv1BusinessDays(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "monday" in value;
    isInstance = isInstance && "tuesday" in value;
    isInstance = isInstance && "wednesday" in value;
    isInstance = isInstance && "thursday" in value;
    isInstance = isInstance && "friday" in value;
    isInstance = isInstance && "saturday" in value;
    isInstance = isInstance && "sunday" in value;

    return isInstance;
}

export function Searchdomainv1BusinessDaysFromJSON(json: any): Searchdomainv1BusinessDays {
    return Searchdomainv1BusinessDaysFromJSONTyped(json, false);
}

export function Searchdomainv1BusinessDaysFromJSONTyped(json: any, ignoreDiscriminator: boolean): Searchdomainv1BusinessDays {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monday': Searchdomainv1BusinessDayFromJSON(json['monday']),
        'tuesday': Searchdomainv1BusinessDayFromJSON(json['tuesday']),
        'wednesday': Searchdomainv1BusinessDayFromJSON(json['wednesday']),
        'thursday': Searchdomainv1BusinessDayFromJSON(json['thursday']),
        'friday': Searchdomainv1BusinessDayFromJSON(json['friday']),
        'saturday': Searchdomainv1BusinessDayFromJSON(json['saturday']),
        'sunday': Searchdomainv1BusinessDayFromJSON(json['sunday']),
    };
}

export function Searchdomainv1BusinessDaysToJSON(value?: Searchdomainv1BusinessDays | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monday': Searchdomainv1BusinessDayToJSON(value.monday),
        'tuesday': Searchdomainv1BusinessDayToJSON(value.tuesday),
        'wednesday': Searchdomainv1BusinessDayToJSON(value.wednesday),
        'thursday': Searchdomainv1BusinessDayToJSON(value.thursday),
        'friday': Searchdomainv1BusinessDayToJSON(value.friday),
        'saturday': Searchdomainv1BusinessDayToJSON(value.saturday),
        'sunday': Searchdomainv1BusinessDayToJSON(value.sunday),
    };
}

