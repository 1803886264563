import { SnackbarProvider } from '@daangn/sprout-components-snackbar'
import { ImpressionRoot } from '@daangn/web-impression'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { CLARITY_ENABLED, IS_LOCAL } from '@src/constants'
import { type PropOf } from '@src/ts-utils/types/utilTypes'

import AppLoading from './AppLoading'
import { BridgeProvider } from './bridge/bridge'
import { BridgeInfoProvider } from './bridge/context/BridgeInfoProvider'
import { GeolocationProvider } from './bridge/context/GeolocationProvider'
import { GlobalEventBusProvider } from './GlobalEventBus'
import { setClarityUserConfig } from './local-map-utils/analytics/clarity'
import { setDatadogRumUserConfig } from './local-map-utils/analytics/dataDogRum'
import { RefetchFailedApiView } from './local-map-utils/error-report/RefetchFailedApiPageComponent'
import { withGeneralErrorBoundary } from './local-map-utils/error-report/withGeneralErrorBoundary'
import { checkKarrotError } from './local-map-utils/karrotError/parseKarrotError'
import { useLogger } from './log/hooks/useLogger'
import LocalMapUserLocationProvider from './provider/LocalMapUserLocationProvider'
import withSuspense from './react-utils/suspense/withSuspense'
import { getQueryFromUrl } from './react-utils/url/useQueryParams'
import { Stack } from './stackflow'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {
  const defaultLogger = useLogger()

  const handleBridgeInfoLoaded: PropOf<
    typeof BridgeInfoProvider,
    'onBridgeInfoLoaded'
  > = ({ app, region, user }) => {
    const { referrer } = getQueryFromUrl<{
      referrer: string
    }>(window.location.href)

    setDatadogRumUserConfig({
      userId: user.id,
      region: region.id,
      userAgent: app.userAgent,
      country: app.country || '',
      deviceIdentity: app.deviceIdentity || '',
      referrer,
    })

    Sentry.setUser({
      id: user.id,
    })
    Sentry.setTags({
      appVersion: app.appVersion,
    })

    if (CLARITY_ENABLED) {
      setClarityUserConfig({
        userId: user.id.toString(),
        deviceIdentity: app.deviceIdentity,
        region: region.id.toString(),
        userAgent: app.userAgent,
        onError: (err) => {
          err.message = `[clarity 초기화 error]: ${err.message}`
          Sentry.captureException(err)
        },
      })
    }
  }

  return (
    <BridgeProvider>
      <BridgeInfoProvider onBridgeInfoLoaded={handleBridgeInfoLoaded}>
        <SnackbarProvider offset="0.75rem">
          <QueryClientProvider client={queryClient}>
            <LocalMapUserLocationProvider>
              <GeolocationProvider>
                <GlobalEventBusProvider>
                  <ImpressionRoot
                    development={IS_LOCAL}
                    impressionOptions={{
                      threshold: 0.75,
                      coolTime: 60000,
                      exposureTime: 1000,
                    }}
                    logger={(logParams) => {
                      defaultLogger(logParams.name, logParams.params)
                    }}
                    initialized={true}
                  >
                    <Stack />
                  </ImpressionRoot>
                </GlobalEventBusProvider>
              </GeolocationProvider>
            </LocalMapUserLocationProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </BridgeInfoProvider>
    </BridgeProvider>
  )
}

export default withGeneralErrorBoundary(
  withSuspense(App, AppLoading),
  ({ error }) => {
    const infraError = error.cause as {
      status: number
      serverResponse: object
    } | null

    const inspectionErrMsg = (() => {
      // 상태가 599면 점검 애러
      if (infraError && infraError.status === 599) {
        const { karrotErrorData } = checkKarrotError(
          (error.cause as { serverResponse?: object })?.serverResponse
        )

        return karrotErrorData?.message
      }
    })()

    return (
      <div style={{ height: '100vh' }}>
        <RefetchFailedApiView
          message={
            inspectionErrMsg ??
            '알 수 없는 오류가 발생했어요\n인터넷 연결을 확인해주세요'
          }
          onRefetchClick={() => {
            window.location.reload()
          }}
        />
      </div>
    )
  },
  {
    isCritical: true,
  }
)
