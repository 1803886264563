/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface DaangnsearchVerticalv1Distance
 */
export interface DaangnsearchVerticalv1Distance {
    /**
     * 
     * @type {boolean}
     * @memberof DaangnsearchVerticalv1Distance
     */
    isValid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DaangnsearchVerticalv1Distance
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof DaangnsearchVerticalv1Distance
     */
    value?: string;
}

/**
 * Check if a given object implements the DaangnsearchVerticalv1Distance interface.
 */
export function instanceOfDaangnsearchVerticalv1Distance(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DaangnsearchVerticalv1DistanceFromJSON(json: any): DaangnsearchVerticalv1Distance {
    return DaangnsearchVerticalv1DistanceFromJSONTyped(json, false);
}

export function DaangnsearchVerticalv1DistanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaangnsearchVerticalv1Distance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isValid': !exists(json, 'isValid') ? undefined : json['isValid'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function DaangnsearchVerticalv1DistanceToJSON(value?: DaangnsearchVerticalv1Distance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isValid': value.isValid,
        'unit': value.unit,
        'value': value.value,
    };
}

