/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1ViewTypeCommerceCoupon } from './V1ViewTypeCommerceCoupon';
import {
    V1ViewTypeCommerceCouponFromJSON,
    V1ViewTypeCommerceCouponFromJSONTyped,
    V1ViewTypeCommerceCouponToJSON,
} from './V1ViewTypeCommerceCoupon';
import type { V1ViewTypeMoreButton } from './V1ViewTypeMoreButton';
import {
    V1ViewTypeMoreButtonFromJSON,
    V1ViewTypeMoreButtonFromJSONTyped,
    V1ViewTypeMoreButtonToJSON,
} from './V1ViewTypeMoreButton';

/**
 * 
 * @export
 * @interface V1ViewTypeSectionRecommendCoupon
 */
export interface V1ViewTypeSectionRecommendCoupon {
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeSectionRecommendCoupon
     */
    title: string;
    /**
     * 
     * @type {Array<V1ViewTypeCommerceCoupon>}
     * @memberof V1ViewTypeSectionRecommendCoupon
     */
    viewTypes: Array<V1ViewTypeCommerceCoupon>;
    /**
     * 
     * @type {V1ViewTypeMoreButton}
     * @memberof V1ViewTypeSectionRecommendCoupon
     */
    moreButton: V1ViewTypeMoreButton;
}

/**
 * Check if a given object implements the V1ViewTypeSectionRecommendCoupon interface.
 */
export function instanceOfV1ViewTypeSectionRecommendCoupon(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "viewTypes" in value;
    isInstance = isInstance && "moreButton" in value;

    return isInstance;
}

export function V1ViewTypeSectionRecommendCouponFromJSON(json: any): V1ViewTypeSectionRecommendCoupon {
    return V1ViewTypeSectionRecommendCouponFromJSONTyped(json, false);
}

export function V1ViewTypeSectionRecommendCouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ViewTypeSectionRecommendCoupon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'viewTypes': ((json['view_types'] as Array<any>).map(V1ViewTypeCommerceCouponFromJSON)),
        'moreButton': V1ViewTypeMoreButtonFromJSON(json['more_button']),
    };
}

export function V1ViewTypeSectionRecommendCouponToJSON(value?: V1ViewTypeSectionRecommendCoupon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'view_types': ((value.viewTypes as Array<any>).map(V1ViewTypeCommerceCouponToJSON)),
        'more_button': V1ViewTypeMoreButtonToJSON(value.moreButton),
    };
}

