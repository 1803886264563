/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1SearchAutoCompleteResultItem } from './V1SearchAutoCompleteResultItem';
import {
    V1SearchAutoCompleteResultItemFromJSON,
    V1SearchAutoCompleteResultItemFromJSONTyped,
    V1SearchAutoCompleteResultItemToJSON,
} from './V1SearchAutoCompleteResultItem';

/**
 * 
 * @export
 * @interface V1SearchAutoCompleteResponse
 */
export interface V1SearchAutoCompleteResponse {
    /**
     * 
     * @type {Array<V1SearchAutoCompleteResultItem>}
     * @memberof V1SearchAutoCompleteResponse
     */
    searchAutoCompleteResultItems: Array<V1SearchAutoCompleteResultItem>;
    /**
     * 
     * @type {string}
     * @memberof V1SearchAutoCompleteResponse
     */
    nextPageToken?: string;
}

/**
 * Check if a given object implements the V1SearchAutoCompleteResponse interface.
 */
export function instanceOfV1SearchAutoCompleteResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "searchAutoCompleteResultItems" in value;

    return isInstance;
}

export function V1SearchAutoCompleteResponseFromJSON(json: any): V1SearchAutoCompleteResponse {
    return V1SearchAutoCompleteResponseFromJSONTyped(json, false);
}

export function V1SearchAutoCompleteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchAutoCompleteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchAutoCompleteResultItems': ((json['search_auto_complete_result_items'] as Array<any>).map(V1SearchAutoCompleteResultItemFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
    };
}

export function V1SearchAutoCompleteResponseToJSON(value?: V1SearchAutoCompleteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'search_auto_complete_result_items': ((value.searchAutoCompleteResultItems as Array<any>).map(V1SearchAutoCompleteResultItemToJSON)),
        'next_page_token': value.nextPageToken,
    };
}

