import {
  createContext,
  type ReactNode,
  useContext,
  useMemo,
  useEffect,
} from 'react'

import {
  type BridgeRegionInfo,
  type BridgeAppInfo,
  type BridgeUserInfo,
} from '@src/bridge/constants'
import { getApp, getRegion, getUser } from '@src/bridge/utils'

export type AppInfo = BridgeAppInfo & {
  os: 'IOS' | 'ANDROID' | 'UNKNOWN'
  osVersion: string
  version: string
}
export type RegionInfo = BridgeRegionInfo
export type UserInfo = BridgeUserInfo
export interface BridgeInfoType {
  app: AppInfo
  region: RegionInfo
  user: UserInfo
}

function extractOperatingSystemFrom(userAgent: string) {
  const [, osInfo] = userAgent.split(' ')
  const [os, version] = (osInfo || 'iOS').split('/')

  const upperCaseOSName = (() => {
    switch (os) {
      case 'iOS':
        return 'IOS'
      case 'Android':
        return 'ANDROID'
      default:
        return 'UNKNOWN'
    }
  })()

  return {
    os: upperCaseOSName,
    version,
  } as const
}

function extractAppVersionFrom(userAgent: string) {
  const [appInfo] = userAgent.split(' ')
  const [, version] = appInfo.split('/')

  return version
}

function makeBridgeInfoResource() {
  type State =
    | {
        _t: 'pending'
      }
    | {
        _t: 'rejected'
        result: Error
      }
    | {
        _t: 'resolved'
        result: BridgeInfoType
      }

  let state: State = {
    _t: 'pending',
  }

  const promise = (async () => {
    try {
      const [app, user, region] = await Promise.all([
        getApp(),
        getUser(),
        getRegion(),
      ])

      if (!app) {
        return void (state = {
          _t: 'rejected',
          result: new Error(
            'Failed to get information to karrotBridge.getAppInfo({})'
          ),
        })
      }

      if (!user || !user.id || !user.nickname || !user.authToken) {
        return void (state = {
          _t: 'rejected',
          result: new Error(
            'Failed to get information to karrotBridge.getUserInfo({})'
          ),
        })
      }

      if (!region || !region.id || !region.name) {
        return void (state = {
          _t: 'rejected',
          result: new Error(
            'Failed to get information to karrotBridge.getRegionInfo({})'
          ),
        })
      }

      const { os, version: osVersion } = extractOperatingSystemFrom(
        app.userAgent
      )

      state = {
        _t: 'resolved',
        result: {
          app: {
            ...app,
            os: os,
            osVersion: osVersion,
            version: extractAppVersionFrom(app.userAgent),
          },
          user,
          region,
        },
      }
    } catch (error: any) {
      state = {
        _t: 'rejected',
        result: error,
      }
    }
  })()

  return {
    read() {
      switch (state._t) {
        case 'pending':
          throw promise
        case 'rejected':
          throw state.result
        case 'resolved':
          return state.result
      }
    },
  }
}

const bridgeInfoResource = makeBridgeInfoResource()

const BridgeInfoResourceContext = createContext<BridgeInfoType>(null as any)

interface BridgeInfoResourceProviderProps {
  children: ReactNode
  onBridgeInfoLoaded?: (info: BridgeInfoType) => void
}

export const BridgeInfoProvider = ({
  children,
  onBridgeInfoLoaded,
}: BridgeInfoResourceProviderProps) => {
  const memoriesBridgeInfo = useMemo(() => {
    const bridgeInfo = bridgeInfoResource.read()

    return {
      app: bridgeInfo.app,
      user: bridgeInfo.user,
      region: bridgeInfo.region,
    }
  }, [])

  useEffect(() => {
    if (!memoriesBridgeInfo) {
      return
    }

    onBridgeInfoLoaded?.(memoriesBridgeInfo)
  }, [memoriesBridgeInfo, onBridgeInfoLoaded])

  return (
    <BridgeInfoResourceContext.Provider value={memoriesBridgeInfo}>
      {children}
    </BridgeInfoResourceContext.Provider>
  )
}

export function useBridgeInfo() {
  return useContext(BridgeInfoResourceContext)
}
