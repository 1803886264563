/**
 * 안드로이드에서 status bar가 투명하게 구성되어 있는경우,
 * safe-area-inset-top을 웹뷰에 적용해주지 않아요.
 *
 * 네이티브에서는 status bar의 높이를 알 수 있기 때문에,
 * status bar의 높이를 cookie 혹은 query string으로 웹뷰 인스턴스에 전달해요.
 *
 * 해당 값을 받아서 --safe-area-inset-top을 css variable로 설정해주어서 사용해요.
 * stackflow 등의 외부 라이브러리의 경우 --safe-area-inset-top의 프로젝트 스콥의 css variable에 의존하지 않고,
 * 시스템에서 제공하는 env(safe-area-inset-top)을 사용하기 때문에,
 * 별도로 해당 값을 주입해주어야 해요.
 */

import { getDeviceType } from '@src/ts-utils/deviceType'

export const ANDROID_ONLY_getSafeAreaInsetTopOverrideValue = () => {
  const params = new URLSearchParams(window.location.search)
  const statusBarHeightQS = params.get('statusBarHeight')
  const safeAreaInsetTopOverrideQS = params.get('safeAreaInsetTopOverride')

  const cookieObject = document.cookie.split(';').reduce(
    (acc, cookie) => {
      const [key, value] = cookie.split('=')
      acc[key.trim()] = value
      return acc
    },
    {} as Record<string, string>
  )

  const safeAreaInsetTopOverrideCookie = cookieObject.safeAreaInsetTopOverride

  return (
    safeAreaInsetTopOverrideCookie ??
    statusBarHeightQS ??
    safeAreaInsetTopOverrideQS ??
    null
  )
}

export const ANDROID_ONLY_safeAreaInsetTopOverride = () => {
  const overrideValue = ANDROID_ONLY_getSafeAreaInsetTopOverrideValue()

  if (!overrideValue) {
    return
  }

  document.documentElement.style.setProperty(
    '--safe-area-inset-top',
    `${overrideValue}px`
  )
}

// iOS safe-area 캐싱된 값을 사용해요
// Tech Spec: https://www.notion.so/daangn/Tech-Spec-Webview-Entry-Loading-FCP-48afd5f704d347a991be56b48be74df1 safe-area 캐싱 부분 참고
export const IOS_ONLY_safeAreaInsetTopOverride = () => {
  const cachedSafeAreaInsetTop = localStorage.getItem(
    'cached-safe-area-inset-top'
  )

  if (cachedSafeAreaInsetTop) {
    // 캐싱값과 env(safe-area-value) 중에서 큰 값으로 선택
    const overrideValue = Math.max(
      parseInt(cachedSafeAreaInsetTop),
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue('--sat')
      )
    )
    document.documentElement.style.setProperty(
      '--safe-area-inset-top',
      `${overrideValue}px`
    )
  }
}

export const setSafeAreaValues = () => {
  const deviceType = getDeviceType()

  switch (deviceType) {
    case 'android':
      ANDROID_ONLY_safeAreaInsetTopOverride()
      break
    case 'ios':
      IOS_ONLY_safeAreaInsetTopOverride()
      break
  }
}
