/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1HttpPostRequestAction
 */
export interface V1HttpPostRequestAction {
    /**
     * 
     * @type {string}
     * @memberof V1HttpPostRequestAction
     */
    url: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof V1HttpPostRequestAction
     */
    headers: { [key: string]: string; };
    /**
     * 
     * @type {object}
     * @memberof V1HttpPostRequestAction
     */
    body?: object;
}

/**
 * Check if a given object implements the V1HttpPostRequestAction interface.
 */
export function instanceOfV1HttpPostRequestAction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "headers" in value;

    return isInstance;
}

export function V1HttpPostRequestActionFromJSON(json: any): V1HttpPostRequestAction {
    return V1HttpPostRequestActionFromJSONTyped(json, false);
}

export function V1HttpPostRequestActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1HttpPostRequestAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'headers': json['headers'],
        'body': !exists(json, 'body') ? undefined : json['body'],
    };
}

export function V1HttpPostRequestActionToJSON(value?: V1HttpPostRequestAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'headers': value.headers,
        'body': value.body,
    };
}

