/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1Action } from './V1Action';
import {
    V1ActionFromJSON,
    V1ActionFromJSONTyped,
    V1ActionToJSON,
} from './V1Action';

/**
 * 
 * @export
 * @interface V1ViewTypeMoreButton
 */
export interface V1ViewTypeMoreButton {
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeMoreButton
     */
    text: string;
    /**
     * 
     * @type {Array<V1Action>}
     * @memberof V1ViewTypeMoreButton
     */
    actions: Array<V1Action>;
}

/**
 * Check if a given object implements the V1ViewTypeMoreButton interface.
 */
export function instanceOfV1ViewTypeMoreButton(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "actions" in value;

    return isInstance;
}

export function V1ViewTypeMoreButtonFromJSON(json: any): V1ViewTypeMoreButton {
    return V1ViewTypeMoreButtonFromJSONTyped(json, false);
}

export function V1ViewTypeMoreButtonFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ViewTypeMoreButton {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': json['text'],
        'actions': ((json['actions'] as Array<any>).map(V1ActionFromJSON)),
    };
}

export function V1ViewTypeMoreButtonToJSON(value?: V1ViewTypeMoreButton | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'actions': ((value.actions as Array<any>).map(V1ActionToJSON)),
    };
}

