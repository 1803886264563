/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface DaangnsearchVerticalv1BusinessHours
 */
export interface DaangnsearchVerticalv1BusinessHours {
    /**
     * 
     * @type {string}
     * @memberof DaangnsearchVerticalv1BusinessHours
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof DaangnsearchVerticalv1BusinessHours
     */
    to?: string;
}

/**
 * Check if a given object implements the DaangnsearchVerticalv1BusinessHours interface.
 */
export function instanceOfDaangnsearchVerticalv1BusinessHours(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DaangnsearchVerticalv1BusinessHoursFromJSON(json: any): DaangnsearchVerticalv1BusinessHours {
    return DaangnsearchVerticalv1BusinessHoursFromJSONTyped(json, false);
}

export function DaangnsearchVerticalv1BusinessHoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaangnsearchVerticalv1BusinessHours {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
    };
}

export function DaangnsearchVerticalv1BusinessHoursToJSON(value?: DaangnsearchVerticalv1BusinessHours | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'to': value.to,
    };
}

