import { useEffect, useMemo, useState } from 'react'

import { useStepRouter } from '@src/hooks/useStepRouter'

import {
  BottomSheetAnchorMapper,
  BottomSheetInitialAnchorIdMapper,
  type HeightViewType,
} from './anchor'

export function useLandscapeAnchors() {
  const { stepType } = useStepRouter()
  const [heightViewType, setHeightViewType] = useState<HeightViewType>(
    checkHeightViewType()
  )

  const anchors = useMemo(
    () => BottomSheetAnchorMapper[heightViewType][stepType],
    [heightViewType, stepType]
  )
  const initialAnchorId = useMemo(
    () => BottomSheetInitialAnchorIdMapper[heightViewType][stepType],
    [heightViewType, stepType]
  )

  useEffect(() => {
    const handleOrientationChange = () => {
      const heightViewType = checkHeightViewType()
      setHeightViewType(heightViewType)
    }

    window.addEventListener('resize', handleOrientationChange)

    return () => {
      window.removeEventListener('resize', handleOrientationChange)
    }
  }, [])

  return {
    anchors,
    initialAnchorId,
  }
}

const BOTTOM_SHEET_LAYOUT_CHANGE_LIMIT = 400
const BOTTOM_SHEET_LAYOUT_CHANGE_LIMIT_MEDIUM = 550

export const checkHeightViewType: () => HeightViewType = () => {
  if (window.innerHeight <= BOTTOM_SHEET_LAYOUT_CHANGE_LIMIT) {
    return 'small'
  }
  if (window.innerHeight <= BOTTOM_SHEET_LAYOUT_CHANGE_LIMIT_MEDIUM) {
    return 'medium'
  }
  return 'large'
}
