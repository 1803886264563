import '../../react-utils/bottom-sheet/bottomSheet.css'

import { type LocalMapStepKeys } from '@src/hooks/useStepRouter'
import { getAnchorPosition } from '@src/react-utils/bottom-sheet/utils'
import { Loading } from '@src/react-utils/loading/Loading'

import {
  BottomSheetInitialAnchorIdMapper,
  NormalizedBottomSheetAnchorMapper,
} from './anchor'
import { checkHeightViewType } from './useLandscapeAnchors'

interface LocalMapBottomSheetSkeletonProps {
  stepType?: LocalMapStepKeys
  children?: React.ReactNode
}

const LocalMapBottomSheetSkeleton = ({
  stepType = 'home',
  children,
}: LocalMapBottomSheetSkeletonProps) => {
  const landspaceType = checkHeightViewType()
  const bottomSheetInitialAnchorId =
    BottomSheetInitialAnchorIdMapper[landspaceType][stepType] ?? 'middle'
  const bottomSheetAnchor =
    NormalizedBottomSheetAnchorMapper[landspaceType][stepType][
      bottomSheetInitialAnchorId
    ]

  const anchorPosition = getAnchorPosition(
    bottomSheetAnchor,
    window.innerHeight
  )

  const withHandle = stepType === 'home'

  return (
    <div
      className={'seed-bottom-sheet'}
      data-part="root"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        userSelect: 'none',
        touchAction: 'none',
        transform: `translateY(${anchorPosition}px)`,
        height: `calc(100vh - ${anchorPosition}px)`,
      }}
    >
      {withHandle && (
        <div className="seed-bottom-sheet" data-part="handle">
          <div className="seed-bottom-sheet" data-part="handlebar" />
        </div>
      )}
      {children ?? <Loading />}
    </div>
  )
}

export default LocalMapBottomSheetSkeleton
