// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { Searchdomainv1BusinessDays } from './Searchdomainv1BusinessDays';
import {
    Searchdomainv1BusinessDaysFromJSON,
    Searchdomainv1BusinessDaysFromJSONTyped,
    Searchdomainv1BusinessDaysToJSON,
} from './Searchdomainv1BusinessDays';
import type { Searchdomainv1BusinessOperation } from './Searchdomainv1BusinessOperation';
import {
    Searchdomainv1BusinessOperationFromJSON,
    Searchdomainv1BusinessOperationFromJSONTyped,
    Searchdomainv1BusinessOperationToJSON,
} from './Searchdomainv1BusinessOperation';
import type { Searchdomainv1Distance } from './Searchdomainv1Distance';
import {
    Searchdomainv1DistanceFromJSON,
    Searchdomainv1DistanceFromJSONTyped,
    Searchdomainv1DistanceToJSON,
} from './Searchdomainv1Distance';
import type { Searchdomainv1HolidayType } from './Searchdomainv1HolidayType';
import {
    Searchdomainv1HolidayTypeFromJSON,
    Searchdomainv1HolidayTypeFromJSONTyped,
    Searchdomainv1HolidayTypeToJSON,
} from './Searchdomainv1HolidayType';

/**
 * 
 * @export
 * @interface PlaceDocumentSource
 */
export interface PlaceDocumentSource {
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    regionId?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    categoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    categoryText?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    pictureId?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    bgImageIds?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PlaceDocumentSource
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof PlaceDocumentSource
     */
    followsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PlaceDocumentSource
     */
    reviewCount?: number;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    displayCategoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    jibunAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    addressExtra?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlaceDocumentSource
     */
    storeOnSale?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    brandBaId?: string;
    /**
     * 
     * @type {number}
     * @memberof PlaceDocumentSource
     */
    poiTaggedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PlaceDocumentSource
     */
    totalReviewCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PlaceDocumentSource
     */
    reservable?: boolean;
    /**
     * 
     * @type {Searchdomainv1BusinessDays}
     * @memberof PlaceDocumentSource
     */
    businessDays?: Searchdomainv1BusinessDays;
    /**
     * 
     * @type {Searchdomainv1HolidayType}
     * @memberof PlaceDocumentSource
     */
    holidayType?: Searchdomainv1HolidayType;
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentSource
     */
    businessDaysExtra?: string;
    /**
     * 
     * @type {Searchdomainv1BusinessOperation}
     * @memberof PlaceDocumentSource
     */
    businessOperation?: Searchdomainv1BusinessOperation;
    /**
     * 
     * @type {Searchdomainv1Distance}
     * @memberof PlaceDocumentSource
     */
    distance?: Searchdomainv1Distance;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlaceDocumentSource
     */
    themeIds?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof PlaceDocumentSource
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlaceDocumentSource
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the PlaceDocumentSource interface.
 */
export function instanceOfPlaceDocumentSource(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function PlaceDocumentSourceFromJSON(json: any): PlaceDocumentSource {
    return PlaceDocumentSourceFromJSONTyped(json, false);
}

export function PlaceDocumentSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceDocumentSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'regionId': !exists(json, 'regionId') ? undefined : json['regionId'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'categoryText': !exists(json, 'categoryText') ? undefined : json['categoryText'],
        'pictureId': !exists(json, 'pictureId') ? undefined : json['pictureId'],
        'bgImageIds': !exists(json, 'bgImageIds') ? undefined : json['bgImageIds'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'name': json['name'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'followsCount': !exists(json, 'followsCount') ? undefined : json['followsCount'],
        'reviewCount': !exists(json, 'reviewCount') ? undefined : json['reviewCount'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'displayCategoryName': !exists(json, 'display_category_name') ? undefined : json['display_category_name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'jibunAddress': !exists(json, 'jibunAddress') ? undefined : json['jibunAddress'],
        'addressExtra': !exists(json, 'addressExtra') ? undefined : json['addressExtra'],
        'storeOnSale': !exists(json, 'storeOnSale') ? undefined : json['storeOnSale'],
        'brandBaId': !exists(json, 'brandBaId') ? undefined : json['brandBaId'],
        'poiTaggedCount': !exists(json, 'poiTaggedCount') ? undefined : json['poiTaggedCount'],
        'totalReviewCount': !exists(json, 'totalReviewCount') ? undefined : json['totalReviewCount'],
        'reservable': !exists(json, 'reservable') ? undefined : json['reservable'],
        'businessDays': !exists(json, 'businessDays') ? undefined : Searchdomainv1BusinessDaysFromJSON(json['businessDays']),
        'holidayType': !exists(json, 'holidayType') ? undefined : Searchdomainv1HolidayTypeFromJSON(json['holidayType']),
        'businessDaysExtra': !exists(json, 'businessDaysExtra') ? undefined : json['businessDaysExtra'],
        'businessOperation': !exists(json, 'businessOperation') ? undefined : Searchdomainv1BusinessOperationFromJSON(json['businessOperation']),
        'distance': !exists(json, 'distance') ? undefined : Searchdomainv1DistanceFromJSON(json['distance']),
        'themeIds': !exists(json, 'themeIds') ? undefined : json['themeIds'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function PlaceDocumentSourceToJSON(value?: PlaceDocumentSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'regionId': value.regionId,
        'categoryId': value.categoryId,
        'categoryText': value.categoryText,
        'pictureId': value.pictureId,
        'bgImageIds': value.bgImageIds,
        'location': value.location,
        'name': value.name,
        'score': value.score,
        'followsCount': value.followsCount,
        'reviewCount': value.reviewCount,
        'description': value.description,
        'displayCategoryName': value.displayCategoryName,
        'address': value.address,
        'jibunAddress': value.jibunAddress,
        'addressExtra': value.addressExtra,
        'storeOnSale': value.storeOnSale,
        'brandBaId': value.brandBaId,
        'poiTaggedCount': value.poiTaggedCount,
        'totalReviewCount': value.totalReviewCount,
        'reservable': value.reservable,
        'businessDays': Searchdomainv1BusinessDaysToJSON(value.businessDays),
        'holidayType': Searchdomainv1HolidayTypeToJSON(value.holidayType),
        'businessDaysExtra': value.businessDaysExtra,
        'businessOperation': Searchdomainv1BusinessOperationToJSON(value.businessOperation),
        'distance': Searchdomainv1DistanceToJSON(value.distance),
        'themeIds': value.themeIds,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}

