/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1PlaceDoc } from './V1PlaceDoc';
import {
    V1PlaceDocFromJSON,
    V1PlaceDocFromJSONTyped,
    V1PlaceDocToJSON,
} from './V1PlaceDoc';
import type { V1SearchFilter } from './V1SearchFilter';
import {
    V1SearchFilterFromJSON,
    V1SearchFilterFromJSONTyped,
    V1SearchFilterToJSON,
} from './V1SearchFilter';
import type { V1SearchQueryPropertyType } from './V1SearchQueryPropertyType';
import {
    V1SearchQueryPropertyTypeFromJSON,
    V1SearchQueryPropertyTypeFromJSONTyped,
    V1SearchQueryPropertyTypeToJSON,
} from './V1SearchQueryPropertyType';

/**
 * 
 * @export
 * @interface V1SearchVerticalBusinessPlacesResponse
 */
export interface V1SearchVerticalBusinessPlacesResponse {
    /**
     * 
     * @type {Array<V1PlaceDoc>}
     * @memberof V1SearchVerticalBusinessPlacesResponse
     */
    placeDoc: Array<V1PlaceDoc>;
    /**
     * 
     * @type {string}
     * @memberof V1SearchVerticalBusinessPlacesResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {V1SearchFilter}
     * @memberof V1SearchVerticalBusinessPlacesResponse
     */
    searchFilter?: V1SearchFilter;
    /**
     * 
     * @type {V1SearchQueryPropertyType}
     * @memberof V1SearchVerticalBusinessPlacesResponse
     */
    queryPropertyType: V1SearchQueryPropertyType;
}

/**
 * Check if a given object implements the V1SearchVerticalBusinessPlacesResponse interface.
 */
export function instanceOfV1SearchVerticalBusinessPlacesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "placeDoc" in value;
    isInstance = isInstance && "queryPropertyType" in value;

    return isInstance;
}

export function V1SearchVerticalBusinessPlacesResponseFromJSON(json: any): V1SearchVerticalBusinessPlacesResponse {
    return V1SearchVerticalBusinessPlacesResponseFromJSONTyped(json, false);
}

export function V1SearchVerticalBusinessPlacesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchVerticalBusinessPlacesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'placeDoc': ((json['place_doc'] as Array<any>).map(V1PlaceDocFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'searchFilter': !exists(json, 'search_filter') ? undefined : V1SearchFilterFromJSON(json['search_filter']),
        'queryPropertyType': V1SearchQueryPropertyTypeFromJSON(json['query_property_type']),
    };
}

export function V1SearchVerticalBusinessPlacesResponseToJSON(value?: V1SearchVerticalBusinessPlacesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'place_doc': ((value.placeDoc as Array<any>).map(V1PlaceDocToJSON)),
        'next_page_token': value.nextPageToken,
        'search_filter': V1SearchFilterToJSON(value.searchFilter),
        'query_property_type': V1SearchQueryPropertyTypeToJSON(value.queryPropertyType),
    };
}

