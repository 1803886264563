/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { Domainv1Label } from './Domainv1Label';
import {
    Domainv1LabelFromJSON,
    Domainv1LabelFromJSONTyped,
    Domainv1LabelToJSON,
} from './Domainv1Label';
import type { V1Model } from './V1Model';
import {
    V1ModelFromJSON,
    V1ModelFromJSONTyped,
    V1ModelToJSON,
} from './V1Model';

/**
 * 
 * @export
 * @interface V1Prediction
 */
export interface V1Prediction {
    /**
     * 
     * @type {V1Model}
     * @memberof V1Prediction
     */
    model?: V1Model;
    /**
     * 
     * @type {Array<Domainv1Label>}
     * @memberof V1Prediction
     */
    labels?: Array<Domainv1Label>;
}

/**
 * Check if a given object implements the V1Prediction interface.
 */
export function instanceOfV1Prediction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function V1PredictionFromJSON(json: any): V1Prediction {
    return V1PredictionFromJSONTyped(json, false);
}

export function V1PredictionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Prediction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'model': !exists(json, 'model') ? undefined : V1ModelFromJSON(json['model']),
        'labels': !exists(json, 'labels') ? undefined : ((json['labels'] as Array<any>).map(Domainv1LabelFromJSON)),
    };
}

export function V1PredictionToJSON(value?: V1Prediction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'model': V1ModelToJSON(value.model),
        'labels': value.labels === undefined ? undefined : ((value.labels as Array<any>).map(Domainv1LabelToJSON)),
    };
}

