/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { DaangnsearchVerticalv1BusinessDay } from './DaangnsearchVerticalv1BusinessDay';
import {
    DaangnsearchVerticalv1BusinessDayFromJSON,
    DaangnsearchVerticalv1BusinessDayFromJSONTyped,
    DaangnsearchVerticalv1BusinessDayToJSON,
} from './DaangnsearchVerticalv1BusinessDay';
import type { DaangnsearchVerticalv1BusinessOperationType } from './DaangnsearchVerticalv1BusinessOperationType';
import {
    DaangnsearchVerticalv1BusinessOperationTypeFromJSON,
    DaangnsearchVerticalv1BusinessOperationTypeFromJSONTyped,
    DaangnsearchVerticalv1BusinessOperationTypeToJSON,
} from './DaangnsearchVerticalv1BusinessOperationType';

/**
 * 
 * @export
 * @interface DaangnsearchVerticalv1BusinessOperation
 */
export interface DaangnsearchVerticalv1BusinessOperation {
    /**
     * 
     * @type {DaangnsearchVerticalv1BusinessOperationType}
     * @memberof DaangnsearchVerticalv1BusinessOperation
     */
    type?: DaangnsearchVerticalv1BusinessOperationType;
    /**
     * 
     * @type {DaangnsearchVerticalv1BusinessDay}
     * @memberof DaangnsearchVerticalv1BusinessOperation
     */
    businessDay?: DaangnsearchVerticalv1BusinessDay;
}

/**
 * Check if a given object implements the DaangnsearchVerticalv1BusinessOperation interface.
 */
export function instanceOfDaangnsearchVerticalv1BusinessOperation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DaangnsearchVerticalv1BusinessOperationFromJSON(json: any): DaangnsearchVerticalv1BusinessOperation {
    return DaangnsearchVerticalv1BusinessOperationFromJSONTyped(json, false);
}

export function DaangnsearchVerticalv1BusinessOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaangnsearchVerticalv1BusinessOperation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : DaangnsearchVerticalv1BusinessOperationTypeFromJSON(json['type']),
        'businessDay': !exists(json, 'businessDay') ? undefined : DaangnsearchVerticalv1BusinessDayFromJSON(json['businessDay']),
    };
}

export function DaangnsearchVerticalv1BusinessOperationToJSON(value?: DaangnsearchVerticalv1BusinessOperation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': DaangnsearchVerticalv1BusinessOperationTypeToJSON(value.type),
        'businessDay': DaangnsearchVerticalv1BusinessDayToJSON(value.businessDay),
    };
}

