/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { Searchdomainv1BusinessDay } from './Searchdomainv1BusinessDay';
import {
    Searchdomainv1BusinessDayFromJSON,
    Searchdomainv1BusinessDayFromJSONTyped,
    Searchdomainv1BusinessDayToJSON,
} from './Searchdomainv1BusinessDay';
import type { Searchdomainv1BusinessOperationType } from './Searchdomainv1BusinessOperationType';
import {
    Searchdomainv1BusinessOperationTypeFromJSON,
    Searchdomainv1BusinessOperationTypeFromJSONTyped,
    Searchdomainv1BusinessOperationTypeToJSON,
} from './Searchdomainv1BusinessOperationType';

/**
 * 
 * @export
 * @interface Searchdomainv1BusinessOperation
 */
export interface Searchdomainv1BusinessOperation {
    /**
     * 
     * @type {Searchdomainv1BusinessOperationType}
     * @memberof Searchdomainv1BusinessOperation
     */
    type?: Searchdomainv1BusinessOperationType;
    /**
     * 
     * @type {Searchdomainv1BusinessDay}
     * @memberof Searchdomainv1BusinessOperation
     */
    businessDay?: Searchdomainv1BusinessDay;
}

/**
 * Check if a given object implements the Searchdomainv1BusinessOperation interface.
 */
export function instanceOfSearchdomainv1BusinessOperation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Searchdomainv1BusinessOperationFromJSON(json: any): Searchdomainv1BusinessOperation {
    return Searchdomainv1BusinessOperationFromJSONTyped(json, false);
}

export function Searchdomainv1BusinessOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Searchdomainv1BusinessOperation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : Searchdomainv1BusinessOperationTypeFromJSON(json['type']),
        'businessDay': !exists(json, 'businessDay') ? undefined : Searchdomainv1BusinessDayFromJSON(json['businessDay']),
    };
}

export function Searchdomainv1BusinessOperationToJSON(value?: Searchdomainv1BusinessOperation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': Searchdomainv1BusinessOperationTypeToJSON(value.type),
        'businessDay': Searchdomainv1BusinessDayToJSON(value.businessDay),
    };
}

