/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1FeedViewType } from './V1FeedViewType';
import {
    V1FeedViewTypeFromJSON,
    V1FeedViewTypeFromJSONTyped,
    V1FeedViewTypeToJSON,
} from './V1FeedViewType';

/**
 * 
 * @export
 * @interface V1ListFeedViewTypesResponse
 */
export interface V1ListFeedViewTypesResponse {
    /**
     * 
     * @type {Array<V1FeedViewType>}
     * @memberof V1ListFeedViewTypesResponse
     */
    viewTypes: Array<V1FeedViewType>;
    /**
     * 
     * @type {string}
     * @memberof V1ListFeedViewTypesResponse
     */
    nextPageToken?: string;
}

/**
 * Check if a given object implements the V1ListFeedViewTypesResponse interface.
 */
export function instanceOfV1ListFeedViewTypesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "viewTypes" in value;

    return isInstance;
}

export function V1ListFeedViewTypesResponseFromJSON(json: any): V1ListFeedViewTypesResponse {
    return V1ListFeedViewTypesResponseFromJSONTyped(json, false);
}

export function V1ListFeedViewTypesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListFeedViewTypesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewTypes': ((json['view_types'] as Array<any>).map(V1FeedViewTypeFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
    };
}

export function V1ListFeedViewTypesResponseToJSON(value?: V1ListFeedViewTypesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'view_types': ((value.viewTypes as Array<any>).map(V1FeedViewTypeToJSON)),
        'next_page_token': value.nextPageToken,
    };
}

