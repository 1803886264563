/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1CheckRegistrableUserLocationResponse
 */
export interface V1CheckRegistrableUserLocationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof V1CheckRegistrableUserLocationResponse
     */
    registrable: boolean;
}

/**
 * Check if a given object implements the V1CheckRegistrableUserLocationResponse interface.
 */
export function instanceOfV1CheckRegistrableUserLocationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "registrable" in value;

    return isInstance;
}

export function V1CheckRegistrableUserLocationResponseFromJSON(json: any): V1CheckRegistrableUserLocationResponse {
    return V1CheckRegistrableUserLocationResponseFromJSONTyped(json, false);
}

export function V1CheckRegistrableUserLocationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1CheckRegistrableUserLocationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrable': json['registrable'],
    };
}

export function V1CheckRegistrableUserLocationResponseToJSON(value?: V1CheckRegistrableUserLocationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrable': value.registrable,
    };
}

