/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../../../runtime';
import type {
  DeleteUserLocationByIdRpcRequest,
  RpcStatus,
  UpdateUserLocationRpcRequest,
  V1AddressAutoCompleteResponse,
  V1AutoCompleteResponse,
  V1CheckRegistrableUserLocationResponse,
  V1CreateUserLocationRequest,
  V1CreateUserLocationResponse,
  V1GetAddressByCoordinateResponse,
  V1ListFeedViewTypesResponse,
  V1ListRecommendationChipsResponse,
  V1ListUserLocationsResponse,
  V1SearchAutoCompleteResponse,
  V1SearchLocalProfilesResponse,
  V1SearchVerticalBusinessPlacesResponse,
  V1UpdateUserLocationResponse,
} from '../models/index';
import {
    DeleteUserLocationByIdRpcRequestFromJSON,
    DeleteUserLocationByIdRpcRequestToJSON,
    RpcStatusFromJSON,
    RpcStatusToJSON,
    UpdateUserLocationRpcRequestFromJSON,
    UpdateUserLocationRpcRequestToJSON,
    V1AddressAutoCompleteResponseFromJSON,
    V1AddressAutoCompleteResponseToJSON,
    V1AutoCompleteResponseFromJSON,
    V1AutoCompleteResponseToJSON,
    V1CheckRegistrableUserLocationResponseFromJSON,
    V1CheckRegistrableUserLocationResponseToJSON,
    V1CreateUserLocationRequestFromJSON,
    V1CreateUserLocationRequestToJSON,
    V1CreateUserLocationResponseFromJSON,
    V1CreateUserLocationResponseToJSON,
    V1GetAddressByCoordinateResponseFromJSON,
    V1GetAddressByCoordinateResponseToJSON,
    V1ListFeedViewTypesResponseFromJSON,
    V1ListFeedViewTypesResponseToJSON,
    V1ListRecommendationChipsResponseFromJSON,
    V1ListRecommendationChipsResponseToJSON,
    V1ListUserLocationsResponseFromJSON,
    V1ListUserLocationsResponseToJSON,
    V1SearchAutoCompleteResponseFromJSON,
    V1SearchAutoCompleteResponseToJSON,
    V1SearchLocalProfilesResponseFromJSON,
    V1SearchLocalProfilesResponseToJSON,
    V1SearchVerticalBusinessPlacesResponseFromJSON,
    V1SearchVerticalBusinessPlacesResponseToJSON,
    V1UpdateUserLocationResponseFromJSON,
    V1UpdateUserLocationResponseToJSON,
} from '../models/index';

export interface LocalMapServiceAddressAutoCompleteRequest {
    prefix: string;
    pageSize: string;
    includeLandAddress: boolean;
    pageToken?: string;
    coordinatesLatitude?: number;
    coordinatesLongitude?: number;
    regionId?: string;
}

export interface LocalMapServiceAutoCompleteRequest {
    query: string;
    coordinatesLatitude?: number;
    coordinatesLongitude?: number;
}

export interface LocalMapServiceCheckRegistrableUserLocationRequest {
    coordinatesLatitude?: number;
    coordinatesLongitude?: number;
}

export interface LocalMapServiceClaimCommerceCouponRequest {
    commerceCouponId: string;
}

export interface LocalMapServiceCreateUserLocationRequest {
    v1CreateUserLocationRequest: V1CreateUserLocationRequest;
}

export interface LocalMapServiceDeleteUserLocationByIdRequest {
    id: string;
    deleteUserLocationByIdRpcRequest: DeleteUserLocationByIdRpcRequest;
}

export interface LocalMapServiceFollowBusinessProfileRequest {
    businessProfileId: string;
}

export interface LocalMapServiceGetAddressByCoordinateRequest {
    coordinateLatitude?: number;
    coordinateLongitude?: number;
}

export interface LocalMapServiceListFeedViewTypesRequest {
    userCoordinatesLatitude?: number;
    userCoordinatesLongitude?: number;
    nextPageToken?: string;
}

export interface LocalMapServiceListRecommendationChipsRequest {
    userCoordinatesLatitude?: number;
    userCoordinatesLongitude?: number;
}

export interface LocalMapServiceSearchAutoCompleteRequest {
    prefix: string;
    coordinatesForSearchLatitude?: number;
    coordinatesForSearchLongitude?: number;
    coordinatesForDistanceLatitude?: number;
    coordinatesForDistanceLongitude?: number;
}

export interface LocalMapServiceSearchLocalProfilesRequest {
    query: string;
    pageSize: number;
    searchEventClientDataQueryId: string;
    searchEventClientDataFunnelFrom: string;
    searchEventClientDataQueryFrom: string;
    searchEventClientDataScreenDepthName: string;
    searchEventClientDataSearchFunnelId: string;
    nextPageToken?: string;
    coordinatesForSearchLatitude?: number;
    coordinatesForSearchLongitude?: number;
    coordinatesForDistanceLatitude?: number;
    coordinatesForDistanceLongitude?: number;
    boundsForSearchCenterLatitude?: number;
    boundsForSearchCenterLongitude?: number;
    boundsForSearchNorthWestLatitude?: number;
    boundsForSearchNorthWestLongitude?: number;
    boundsForSearchSouthEastLatitude?: number;
    boundsForSearchSouthEastLongitude?: number;
}

export interface LocalMapServiceSearchVerticalBusinessPlacesRequest {
    query: string;
    pageSize: string;
    sortType: LocalMapServiceSearchVerticalBusinessPlacesSortTypeEnum;
    searchEventClientDataQueryId: string;
    searchEventClientDataFunnelFrom: string;
    searchEventClientDataQueryFrom: string;
    searchEventClientDataScreenDepthName: string;
    searchEventClientDataSearchFunnelId: string;
    pageToken?: string;
    locationLatitude?: number;
    locationLongitude?: number;
    boundsCenterLatitude?: number;
    boundsCenterLongitude?: number;
    boundsNorthWestLatitude?: number;
    boundsNorthWestLongitude?: number;
    boundsSouthEastLatitude?: number;
    boundsSouthEastLongitude?: number;
    mainLocationLatitude?: number;
    mainLocationLongitude?: number;
    useMap?: boolean;
}

export interface LocalMapServiceUpdateUserLocationRequest {
    id: string;
    updateUserLocationRpcRequest: UpdateUserLocationRpcRequest;
}

/**
 * 
 */
export class LocalMapServiceApi extends runtime.BaseAPI {

    /**
     * 집주소 검색 자동완성
     */
    async localMapServiceAddressAutoCompleteRaw(requestParameters: LocalMapServiceAddressAutoCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1AddressAutoCompleteResponse>> {
        if (requestParameters.prefix === null || requestParameters.prefix === undefined) {
            throw new runtime.RequiredError('prefix','Required parameter requestParameters.prefix was null or undefined when calling localMapServiceAddressAutoComplete.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling localMapServiceAddressAutoComplete.');
        }

        if (requestParameters.includeLandAddress === null || requestParameters.includeLandAddress === undefined) {
            throw new runtime.RequiredError('includeLandAddress','Required parameter requestParameters.includeLandAddress was null or undefined when calling localMapServiceAddressAutoComplete.');
        }

        const queryParameters: any = {};

        if (requestParameters.prefix !== undefined) {
            queryParameters['prefix'] = requestParameters.prefix;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        if (requestParameters.coordinatesLatitude !== undefined) {
            queryParameters['coordinates.latitude'] = requestParameters.coordinatesLatitude;
        }

        if (requestParameters.coordinatesLongitude !== undefined) {
            queryParameters['coordinates.longitude'] = requestParameters.coordinatesLongitude;
        }

        if (requestParameters.regionId !== undefined) {
            queryParameters['region_id'] = requestParameters.regionId;
        }

        if (requestParameters.includeLandAddress !== undefined) {
            queryParameters['include_land_address'] = requestParameters.includeLandAddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/address-auto-complete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1AddressAutoCompleteResponseFromJSON(jsonValue));
    }

    /**
     * 집주소 검색 자동완성
     */
    async localMapServiceAddressAutoComplete(requestParameters: LocalMapServiceAddressAutoCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1AddressAutoCompleteResponse> {
        const response = await this.localMapServiceAddressAutoCompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 메인 검색창 자동완성
     */
    async localMapServiceAutoCompleteRaw(requestParameters: LocalMapServiceAutoCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1AutoCompleteResponse>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling localMapServiceAutoComplete.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.coordinatesLatitude !== undefined) {
            queryParameters['coordinates.latitude'] = requestParameters.coordinatesLatitude;
        }

        if (requestParameters.coordinatesLongitude !== undefined) {
            queryParameters['coordinates.longitude'] = requestParameters.coordinatesLongitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/auto-complete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1AutoCompleteResponseFromJSON(jsonValue));
    }

    /**
     * 메인 검색창 자동완성
     */
    async localMapServiceAutoComplete(requestParameters: LocalMapServiceAutoCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1AutoCompleteResponse> {
        const response = await this.localMapServiceAutoCompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 대표위치로 등록가능 좌표 여부 확인
     */
    async localMapServiceCheckRegistrableUserLocationRaw(requestParameters: LocalMapServiceCheckRegistrableUserLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1CheckRegistrableUserLocationResponse>> {
        const queryParameters: any = {};

        if (requestParameters.coordinatesLatitude !== undefined) {
            queryParameters['coordinates.latitude'] = requestParameters.coordinatesLatitude;
        }

        if (requestParameters.coordinatesLongitude !== undefined) {
            queryParameters['coordinates.longitude'] = requestParameters.coordinatesLongitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/check-registrable-user-location`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1CheckRegistrableUserLocationResponseFromJSON(jsonValue));
    }

    /**
     * 대표위치로 등록가능 좌표 여부 확인
     */
    async localMapServiceCheckRegistrableUserLocation(requestParameters: LocalMapServiceCheckRegistrableUserLocationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1CheckRegistrableUserLocationResponse> {
        const response = await this.localMapServiceCheckRegistrableUserLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 쿠폰을 받아요
     */
    async localMapServiceClaimCommerceCouponRaw(requestParameters: LocalMapServiceClaimCommerceCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.commerceCouponId === null || requestParameters.commerceCouponId === undefined) {
            throw new runtime.RequiredError('commerceCouponId','Required parameter requestParameters.commerceCouponId was null or undefined when calling localMapServiceClaimCommerceCoupon.');
        }

        const queryParameters: any = {};

        if (requestParameters.commerceCouponId !== undefined) {
            queryParameters['commerce_coupon_id'] = requestParameters.commerceCouponId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/commerce-coupon/claim`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 쿠폰을 받아요
     */
    async localMapServiceClaimCommerceCoupon(requestParameters: LocalMapServiceClaimCommerceCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.localMapServiceClaimCommerceCouponRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 사용자 위치 정보를 생성해요
     */
    async localMapServiceCreateUserLocationRaw(requestParameters: LocalMapServiceCreateUserLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1CreateUserLocationResponse>> {
        if (requestParameters.v1CreateUserLocationRequest === null || requestParameters.v1CreateUserLocationRequest === undefined) {
            throw new runtime.RequiredError('v1CreateUserLocationRequest','Required parameter requestParameters.v1CreateUserLocationRequest was null or undefined when calling localMapServiceCreateUserLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user-location`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: V1CreateUserLocationRequestToJSON(requestParameters.v1CreateUserLocationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1CreateUserLocationResponseFromJSON(jsonValue));
    }

    /**
     * 사용자 위치 정보를 생성해요
     */
    async localMapServiceCreateUserLocation(requestParameters: LocalMapServiceCreateUserLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1CreateUserLocationResponse> {
        const response = await this.localMapServiceCreateUserLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 사용자 위치 정보를 삭제해요
     */
    async localMapServiceDeleteUserLocationByIdRaw(requestParameters: LocalMapServiceDeleteUserLocationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling localMapServiceDeleteUserLocationById.');
        }

        if (requestParameters.deleteUserLocationByIdRpcRequest === null || requestParameters.deleteUserLocationByIdRpcRequest === undefined) {
            throw new runtime.RequiredError('deleteUserLocationByIdRpcRequest','Required parameter requestParameters.deleteUserLocationByIdRpcRequest was null or undefined when calling localMapServiceDeleteUserLocationById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user-location/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteUserLocationByIdRpcRequestToJSON(requestParameters.deleteUserLocationByIdRpcRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 사용자 위치 정보를 삭제해요
     */
    async localMapServiceDeleteUserLocationById(requestParameters: LocalMapServiceDeleteUserLocationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.localMapServiceDeleteUserLocationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 비즈프로필 단골을 추가해요
     */
    async localMapServiceFollowBusinessProfileRaw(requestParameters: LocalMapServiceFollowBusinessProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.businessProfileId === null || requestParameters.businessProfileId === undefined) {
            throw new runtime.RequiredError('businessProfileId','Required parameter requestParameters.businessProfileId was null or undefined when calling localMapServiceFollowBusinessProfile.');
        }

        const queryParameters: any = {};

        if (requestParameters.businessProfileId !== undefined) {
            queryParameters['business_profile_id'] = requestParameters.businessProfileId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/business-profile/follow`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 비즈프로필 단골을 추가해요
     */
    async localMapServiceFollowBusinessProfile(requestParameters: LocalMapServiceFollowBusinessProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.localMapServiceFollowBusinessProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 좌표로 주소를 가져와요
     */
    async localMapServiceGetAddressByCoordinateRaw(requestParameters: LocalMapServiceGetAddressByCoordinateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1GetAddressByCoordinateResponse>> {
        const queryParameters: any = {};

        if (requestParameters.coordinateLatitude !== undefined) {
            queryParameters['coordinate.latitude'] = requestParameters.coordinateLatitude;
        }

        if (requestParameters.coordinateLongitude !== undefined) {
            queryParameters['coordinate.longitude'] = requestParameters.coordinateLongitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/geocode/address`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1GetAddressByCoordinateResponseFromJSON(jsonValue));
    }

    /**
     * 좌표로 주소를 가져와요
     */
    async localMapServiceGetAddressByCoordinate(requestParameters: LocalMapServiceGetAddressByCoordinateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1GetAddressByCoordinateResponse> {
        const response = await this.localMapServiceGetAddressByCoordinateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 피드 뷰타입을 조회해요
     */
    async localMapServiceListFeedViewTypesRaw(requestParameters: LocalMapServiceListFeedViewTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListFeedViewTypesResponse>> {
        const queryParameters: any = {};

        if (requestParameters.userCoordinatesLatitude !== undefined) {
            queryParameters['user_coordinates.latitude'] = requestParameters.userCoordinatesLatitude;
        }

        if (requestParameters.userCoordinatesLongitude !== undefined) {
            queryParameters['user_coordinates.longitude'] = requestParameters.userCoordinatesLongitude;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['next_page_token'] = requestParameters.nextPageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/feed/view-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListFeedViewTypesResponseFromJSON(jsonValue));
    }

    /**
     * 피드 뷰타입을 조회해요
     */
    async localMapServiceListFeedViewTypes(requestParameters: LocalMapServiceListFeedViewTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListFeedViewTypesResponse> {
        const response = await this.localMapServiceListFeedViewTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 추천 칩을 모두 가져와요
     */
    async localMapServiceListRecommendationChipsRaw(requestParameters: LocalMapServiceListRecommendationChipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListRecommendationChipsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.userCoordinatesLatitude !== undefined) {
            queryParameters['user_coordinates.latitude'] = requestParameters.userCoordinatesLatitude;
        }

        if (requestParameters.userCoordinatesLongitude !== undefined) {
            queryParameters['user_coordinates.longitude'] = requestParameters.userCoordinatesLongitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/recommendation-chips`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListRecommendationChipsResponseFromJSON(jsonValue));
    }

    /**
     * 추천 칩을 모두 가져와요
     */
    async localMapServiceListRecommendationChips(requestParameters: LocalMapServiceListRecommendationChipsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListRecommendationChipsResponse> {
        const response = await this.localMapServiceListRecommendationChipsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 요청하는 유저의 위치 정보를 모두 가져와요
     */
    async localMapServiceListUserLocationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListUserLocationsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/me/user-locations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListUserLocationsResponseFromJSON(jsonValue));
    }

    /**
     * 요청하는 유저의 위치 정보를 모두 가져와요
     */
    async localMapServiceListUserLocations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListUserLocationsResponse> {
        const response = await this.localMapServiceListUserLocationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 메인 검색 자동완성
     */
    async localMapServiceSearchAutoCompleteRaw(requestParameters: LocalMapServiceSearchAutoCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1SearchAutoCompleteResponse>> {
        if (requestParameters.prefix === null || requestParameters.prefix === undefined) {
            throw new runtime.RequiredError('prefix','Required parameter requestParameters.prefix was null or undefined when calling localMapServiceSearchAutoComplete.');
        }

        const queryParameters: any = {};

        if (requestParameters.prefix !== undefined) {
            queryParameters['prefix'] = requestParameters.prefix;
        }

        if (requestParameters.coordinatesForSearchLatitude !== undefined) {
            queryParameters['coordinates_for_search.latitude'] = requestParameters.coordinatesForSearchLatitude;
        }

        if (requestParameters.coordinatesForSearchLongitude !== undefined) {
            queryParameters['coordinates_for_search.longitude'] = requestParameters.coordinatesForSearchLongitude;
        }

        if (requestParameters.coordinatesForDistanceLatitude !== undefined) {
            queryParameters['coordinates_for_distance.latitude'] = requestParameters.coordinatesForDistanceLatitude;
        }

        if (requestParameters.coordinatesForDistanceLongitude !== undefined) {
            queryParameters['coordinates_for_distance.longitude'] = requestParameters.coordinatesForDistanceLongitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/search-auto-complete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1SearchAutoCompleteResponseFromJSON(jsonValue));
    }

    /**
     * 메인 검색 자동완성
     */
    async localMapServiceSearchAutoComplete(requestParameters: LocalMapServiceSearchAutoCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1SearchAutoCompleteResponse> {
        const response = await this.localMapServiceSearchAutoCompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 로컬 프로필을 검색해요
     */
    async localMapServiceSearchLocalProfilesRaw(requestParameters: LocalMapServiceSearchLocalProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1SearchLocalProfilesResponse>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling localMapServiceSearchLocalProfiles.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling localMapServiceSearchLocalProfiles.');
        }

        if (requestParameters.searchEventClientDataQueryId === null || requestParameters.searchEventClientDataQueryId === undefined) {
            throw new runtime.RequiredError('searchEventClientDataQueryId','Required parameter requestParameters.searchEventClientDataQueryId was null or undefined when calling localMapServiceSearchLocalProfiles.');
        }

        if (requestParameters.searchEventClientDataFunnelFrom === null || requestParameters.searchEventClientDataFunnelFrom === undefined) {
            throw new runtime.RequiredError('searchEventClientDataFunnelFrom','Required parameter requestParameters.searchEventClientDataFunnelFrom was null or undefined when calling localMapServiceSearchLocalProfiles.');
        }

        if (requestParameters.searchEventClientDataQueryFrom === null || requestParameters.searchEventClientDataQueryFrom === undefined) {
            throw new runtime.RequiredError('searchEventClientDataQueryFrom','Required parameter requestParameters.searchEventClientDataQueryFrom was null or undefined when calling localMapServiceSearchLocalProfiles.');
        }

        if (requestParameters.searchEventClientDataScreenDepthName === null || requestParameters.searchEventClientDataScreenDepthName === undefined) {
            throw new runtime.RequiredError('searchEventClientDataScreenDepthName','Required parameter requestParameters.searchEventClientDataScreenDepthName was null or undefined when calling localMapServiceSearchLocalProfiles.');
        }

        if (requestParameters.searchEventClientDataSearchFunnelId === null || requestParameters.searchEventClientDataSearchFunnelId === undefined) {
            throw new runtime.RequiredError('searchEventClientDataSearchFunnelId','Required parameter requestParameters.searchEventClientDataSearchFunnelId was null or undefined when calling localMapServiceSearchLocalProfiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['next_page_token'] = requestParameters.nextPageToken;
        }

        if (requestParameters.coordinatesForSearchLatitude !== undefined) {
            queryParameters['coordinates_for_search.latitude'] = requestParameters.coordinatesForSearchLatitude;
        }

        if (requestParameters.coordinatesForSearchLongitude !== undefined) {
            queryParameters['coordinates_for_search.longitude'] = requestParameters.coordinatesForSearchLongitude;
        }

        if (requestParameters.coordinatesForDistanceLatitude !== undefined) {
            queryParameters['coordinates_for_distance.latitude'] = requestParameters.coordinatesForDistanceLatitude;
        }

        if (requestParameters.coordinatesForDistanceLongitude !== undefined) {
            queryParameters['coordinates_for_distance.longitude'] = requestParameters.coordinatesForDistanceLongitude;
        }

        if (requestParameters.boundsForSearchCenterLatitude !== undefined) {
            queryParameters['bounds_for_search.center.latitude'] = requestParameters.boundsForSearchCenterLatitude;
        }

        if (requestParameters.boundsForSearchCenterLongitude !== undefined) {
            queryParameters['bounds_for_search.center.longitude'] = requestParameters.boundsForSearchCenterLongitude;
        }

        if (requestParameters.boundsForSearchNorthWestLatitude !== undefined) {
            queryParameters['bounds_for_search.north_west.latitude'] = requestParameters.boundsForSearchNorthWestLatitude;
        }

        if (requestParameters.boundsForSearchNorthWestLongitude !== undefined) {
            queryParameters['bounds_for_search.north_west.longitude'] = requestParameters.boundsForSearchNorthWestLongitude;
        }

        if (requestParameters.boundsForSearchSouthEastLatitude !== undefined) {
            queryParameters['bounds_for_search.south_east.latitude'] = requestParameters.boundsForSearchSouthEastLatitude;
        }

        if (requestParameters.boundsForSearchSouthEastLongitude !== undefined) {
            queryParameters['bounds_for_search.south_east.longitude'] = requestParameters.boundsForSearchSouthEastLongitude;
        }

        if (requestParameters.searchEventClientDataQueryId !== undefined) {
            queryParameters['search_event_client_data.query_id'] = requestParameters.searchEventClientDataQueryId;
        }

        if (requestParameters.searchEventClientDataFunnelFrom !== undefined) {
            queryParameters['search_event_client_data.funnel_from'] = requestParameters.searchEventClientDataFunnelFrom;
        }

        if (requestParameters.searchEventClientDataQueryFrom !== undefined) {
            queryParameters['search_event_client_data.query_from'] = requestParameters.searchEventClientDataQueryFrom;
        }

        if (requestParameters.searchEventClientDataScreenDepthName !== undefined) {
            queryParameters['search_event_client_data.screen_depth_name'] = requestParameters.searchEventClientDataScreenDepthName;
        }

        if (requestParameters.searchEventClientDataSearchFunnelId !== undefined) {
            queryParameters['search_event_client_data.search_funnel_id'] = requestParameters.searchEventClientDataSearchFunnelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/search/local-profiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1SearchLocalProfilesResponseFromJSON(jsonValue));
    }

    /**
     * 로컬 프로필을 검색해요
     */
    async localMapServiceSearchLocalProfiles(requestParameters: LocalMapServiceSearchLocalProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1SearchLocalProfilesResponse> {
        const response = await this.localMapServiceSearchLocalProfilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async localMapServiceSearchVerticalBusinessPlacesRaw(requestParameters: LocalMapServiceSearchVerticalBusinessPlacesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1SearchVerticalBusinessPlacesResponse>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling localMapServiceSearchVerticalBusinessPlaces.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling localMapServiceSearchVerticalBusinessPlaces.');
        }

        if (requestParameters.sortType === null || requestParameters.sortType === undefined) {
            throw new runtime.RequiredError('sortType','Required parameter requestParameters.sortType was null or undefined when calling localMapServiceSearchVerticalBusinessPlaces.');
        }

        if (requestParameters.searchEventClientDataQueryId === null || requestParameters.searchEventClientDataQueryId === undefined) {
            throw new runtime.RequiredError('searchEventClientDataQueryId','Required parameter requestParameters.searchEventClientDataQueryId was null or undefined when calling localMapServiceSearchVerticalBusinessPlaces.');
        }

        if (requestParameters.searchEventClientDataFunnelFrom === null || requestParameters.searchEventClientDataFunnelFrom === undefined) {
            throw new runtime.RequiredError('searchEventClientDataFunnelFrom','Required parameter requestParameters.searchEventClientDataFunnelFrom was null or undefined when calling localMapServiceSearchVerticalBusinessPlaces.');
        }

        if (requestParameters.searchEventClientDataQueryFrom === null || requestParameters.searchEventClientDataQueryFrom === undefined) {
            throw new runtime.RequiredError('searchEventClientDataQueryFrom','Required parameter requestParameters.searchEventClientDataQueryFrom was null or undefined when calling localMapServiceSearchVerticalBusinessPlaces.');
        }

        if (requestParameters.searchEventClientDataScreenDepthName === null || requestParameters.searchEventClientDataScreenDepthName === undefined) {
            throw new runtime.RequiredError('searchEventClientDataScreenDepthName','Required parameter requestParameters.searchEventClientDataScreenDepthName was null or undefined when calling localMapServiceSearchVerticalBusinessPlaces.');
        }

        if (requestParameters.searchEventClientDataSearchFunnelId === null || requestParameters.searchEventClientDataSearchFunnelId === undefined) {
            throw new runtime.RequiredError('searchEventClientDataSearchFunnelId','Required parameter requestParameters.searchEventClientDataSearchFunnelId was null or undefined when calling localMapServiceSearchVerticalBusinessPlaces.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        if (requestParameters.sortType !== undefined) {
            queryParameters['sort_type'] = requestParameters.sortType;
        }

        if (requestParameters.locationLatitude !== undefined) {
            queryParameters['location.latitude'] = requestParameters.locationLatitude;
        }

        if (requestParameters.locationLongitude !== undefined) {
            queryParameters['location.longitude'] = requestParameters.locationLongitude;
        }

        if (requestParameters.boundsCenterLatitude !== undefined) {
            queryParameters['bounds.center.latitude'] = requestParameters.boundsCenterLatitude;
        }

        if (requestParameters.boundsCenterLongitude !== undefined) {
            queryParameters['bounds.center.longitude'] = requestParameters.boundsCenterLongitude;
        }

        if (requestParameters.boundsNorthWestLatitude !== undefined) {
            queryParameters['bounds.north_west.latitude'] = requestParameters.boundsNorthWestLatitude;
        }

        if (requestParameters.boundsNorthWestLongitude !== undefined) {
            queryParameters['bounds.north_west.longitude'] = requestParameters.boundsNorthWestLongitude;
        }

        if (requestParameters.boundsSouthEastLatitude !== undefined) {
            queryParameters['bounds.south_east.latitude'] = requestParameters.boundsSouthEastLatitude;
        }

        if (requestParameters.boundsSouthEastLongitude !== undefined) {
            queryParameters['bounds.south_east.longitude'] = requestParameters.boundsSouthEastLongitude;
        }

        if (requestParameters.mainLocationLatitude !== undefined) {
            queryParameters['main_location.latitude'] = requestParameters.mainLocationLatitude;
        }

        if (requestParameters.mainLocationLongitude !== undefined) {
            queryParameters['main_location.longitude'] = requestParameters.mainLocationLongitude;
        }

        if (requestParameters.searchEventClientDataQueryId !== undefined) {
            queryParameters['search_event_client_data.query_id'] = requestParameters.searchEventClientDataQueryId;
        }

        if (requestParameters.searchEventClientDataFunnelFrom !== undefined) {
            queryParameters['search_event_client_data.funnel_from'] = requestParameters.searchEventClientDataFunnelFrom;
        }

        if (requestParameters.searchEventClientDataQueryFrom !== undefined) {
            queryParameters['search_event_client_data.query_from'] = requestParameters.searchEventClientDataQueryFrom;
        }

        if (requestParameters.searchEventClientDataScreenDepthName !== undefined) {
            queryParameters['search_event_client_data.screen_depth_name'] = requestParameters.searchEventClientDataScreenDepthName;
        }

        if (requestParameters.searchEventClientDataSearchFunnelId !== undefined) {
            queryParameters['search_event_client_data.search_funnel_id'] = requestParameters.searchEventClientDataSearchFunnelId;
        }

        if (requestParameters.useMap !== undefined) {
            queryParameters['use_map'] = requestParameters.useMap;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/search-vertical/business-places`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1SearchVerticalBusinessPlacesResponseFromJSON(jsonValue));
    }

    /**
     */
    async localMapServiceSearchVerticalBusinessPlaces(requestParameters: LocalMapServiceSearchVerticalBusinessPlacesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1SearchVerticalBusinessPlacesResponse> {
        const response = await this.localMapServiceSearchVerticalBusinessPlacesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 사용자 위치 정보를 수정해요
     */
    async localMapServiceUpdateUserLocationRaw(requestParameters: LocalMapServiceUpdateUserLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1UpdateUserLocationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling localMapServiceUpdateUserLocation.');
        }

        if (requestParameters.updateUserLocationRpcRequest === null || requestParameters.updateUserLocationRpcRequest === undefined) {
            throw new runtime.RequiredError('updateUserLocationRpcRequest','Required parameter requestParameters.updateUserLocationRpcRequest was null or undefined when calling localMapServiceUpdateUserLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Auth-Token"] = this.configuration.apiKey("X-Auth-Token"); // XAuthToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user-location/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserLocationRpcRequestToJSON(requestParameters.updateUserLocationRpcRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1UpdateUserLocationResponseFromJSON(jsonValue));
    }

    /**
     * 사용자 위치 정보를 수정해요
     */
    async localMapServiceUpdateUserLocation(requestParameters: LocalMapServiceUpdateUserLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1UpdateUserLocationResponse> {
        const response = await this.localMapServiceUpdateUserLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const LocalMapServiceSearchVerticalBusinessPlacesSortTypeEnum = {
    Unspecified: 'BUSINESS_PLACE_SORT_TYPE_UNSPECIFIED',
    Relevant: 'BUSINESS_PLACE_SORT_TYPE_RELEVANT',
    Distance: 'BUSINESS_PLACE_SORT_TYPE_DISTANCE'
} as const;
export type LocalMapServiceSearchVerticalBusinessPlacesSortTypeEnum = typeof LocalMapServiceSearchVerticalBusinessPlacesSortTypeEnum[keyof typeof LocalMapServiceSearchVerticalBusinessPlacesSortTypeEnum];
