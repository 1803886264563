// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1DistanceUnit } from './V1DistanceUnit';
import {
    V1DistanceUnitFromJSON,
    V1DistanceUnitFromJSONTyped,
    V1DistanceUnitToJSON,
} from './V1DistanceUnit';

/**
 * 
 * @export
 * @interface Searchdomainv1Distance
 */
export interface Searchdomainv1Distance {
    /**
     * 
     * @type {boolean}
     * @memberof Searchdomainv1Distance
     */
    isValid?: boolean;
    /**
     * 
     * @type {V1DistanceUnit}
     * @memberof Searchdomainv1Distance
     */
    unit?: V1DistanceUnit;
    /**
     * 
     * @type {number}
     * @memberof Searchdomainv1Distance
     */
    value?: number;
}

/**
 * Check if a given object implements the Searchdomainv1Distance interface.
 */
export function instanceOfSearchdomainv1Distance(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Searchdomainv1DistanceFromJSON(json: any): Searchdomainv1Distance {
    return Searchdomainv1DistanceFromJSONTyped(json, false);
}

export function Searchdomainv1DistanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Searchdomainv1Distance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isValid': !exists(json, 'is_valid') ? undefined : json['is_valid'],
        'unit': !exists(json, 'unit') ? undefined : V1DistanceUnitFromJSON(json['unit']),
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function Searchdomainv1DistanceToJSON(value?: Searchdomainv1Distance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isValid': value.isValid,
        'unit': V1DistanceUnitToJSON(value.unit),
        'value': value.value,
    };
}

