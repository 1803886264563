/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { DaangnsearchVerticalv1BusinessDay } from './DaangnsearchVerticalv1BusinessDay';
import {
    DaangnsearchVerticalv1BusinessDayFromJSON,
    DaangnsearchVerticalv1BusinessDayFromJSONTyped,
    DaangnsearchVerticalv1BusinessDayToJSON,
} from './DaangnsearchVerticalv1BusinessDay';

/**
 * 
 * @export
 * @interface DaangnsearchVerticalv1BusinessDays
 */
export interface DaangnsearchVerticalv1BusinessDays {
    /**
     * 
     * @type {DaangnsearchVerticalv1BusinessDay}
     * @memberof DaangnsearchVerticalv1BusinessDays
     */
    monday?: DaangnsearchVerticalv1BusinessDay;
    /**
     * 
     * @type {DaangnsearchVerticalv1BusinessDay}
     * @memberof DaangnsearchVerticalv1BusinessDays
     */
    tuesday?: DaangnsearchVerticalv1BusinessDay;
    /**
     * 
     * @type {DaangnsearchVerticalv1BusinessDay}
     * @memberof DaangnsearchVerticalv1BusinessDays
     */
    wednesday?: DaangnsearchVerticalv1BusinessDay;
    /**
     * 
     * @type {DaangnsearchVerticalv1BusinessDay}
     * @memberof DaangnsearchVerticalv1BusinessDays
     */
    thursday?: DaangnsearchVerticalv1BusinessDay;
    /**
     * 
     * @type {DaangnsearchVerticalv1BusinessDay}
     * @memberof DaangnsearchVerticalv1BusinessDays
     */
    friday?: DaangnsearchVerticalv1BusinessDay;
    /**
     * 
     * @type {DaangnsearchVerticalv1BusinessDay}
     * @memberof DaangnsearchVerticalv1BusinessDays
     */
    saturday?: DaangnsearchVerticalv1BusinessDay;
    /**
     * 
     * @type {DaangnsearchVerticalv1BusinessDay}
     * @memberof DaangnsearchVerticalv1BusinessDays
     */
    sunday?: DaangnsearchVerticalv1BusinessDay;
}

/**
 * Check if a given object implements the DaangnsearchVerticalv1BusinessDays interface.
 */
export function instanceOfDaangnsearchVerticalv1BusinessDays(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DaangnsearchVerticalv1BusinessDaysFromJSON(json: any): DaangnsearchVerticalv1BusinessDays {
    return DaangnsearchVerticalv1BusinessDaysFromJSONTyped(json, false);
}

export function DaangnsearchVerticalv1BusinessDaysFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaangnsearchVerticalv1BusinessDays {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monday': !exists(json, 'monday') ? undefined : DaangnsearchVerticalv1BusinessDayFromJSON(json['monday']),
        'tuesday': !exists(json, 'tuesday') ? undefined : DaangnsearchVerticalv1BusinessDayFromJSON(json['tuesday']),
        'wednesday': !exists(json, 'wednesday') ? undefined : DaangnsearchVerticalv1BusinessDayFromJSON(json['wednesday']),
        'thursday': !exists(json, 'thursday') ? undefined : DaangnsearchVerticalv1BusinessDayFromJSON(json['thursday']),
        'friday': !exists(json, 'friday') ? undefined : DaangnsearchVerticalv1BusinessDayFromJSON(json['friday']),
        'saturday': !exists(json, 'saturday') ? undefined : DaangnsearchVerticalv1BusinessDayFromJSON(json['saturday']),
        'sunday': !exists(json, 'sunday') ? undefined : DaangnsearchVerticalv1BusinessDayFromJSON(json['sunday']),
    };
}

export function DaangnsearchVerticalv1BusinessDaysToJSON(value?: DaangnsearchVerticalv1BusinessDays | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monday': DaangnsearchVerticalv1BusinessDayToJSON(value.monday),
        'tuesday': DaangnsearchVerticalv1BusinessDayToJSON(value.tuesday),
        'wednesday': DaangnsearchVerticalv1BusinessDayToJSON(value.wednesday),
        'thursday': DaangnsearchVerticalv1BusinessDayToJSON(value.thursday),
        'friday': DaangnsearchVerticalv1BusinessDayToJSON(value.friday),
        'saturday': DaangnsearchVerticalv1BusinessDayToJSON(value.saturday),
        'sunday': DaangnsearchVerticalv1BusinessDayToJSON(value.sunday),
    };
}

