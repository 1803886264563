import { keyBy, mapValues } from 'lodash-es'

import { type LocalMapStepKeys } from '@src/hooks/useStepRouter'
import { MAP_TOP_AREA_SIZE_MAPPER } from '@src/pages/home-activity/_constants/size'
import { type Anchor } from '@src/react-utils/bottom-sheet/types'

const HeightViewTypes = ['small', 'medium', 'large'] as const
export type HeightViewType = (typeof HeightViewTypes)[number]

export type LandscapeAnchors = Record<
  HeightViewType,
  LocalMapBottomSheetAnchorKeys
>

export type LocalMapBottomSheetAnchorKeys = Record<LocalMapStepKeys, Anchor[]>

export const MIDDLE_OFFSET = Math.floor(window.innerHeight * 0.35)

const smallHeightBottomSheetAnchorMapper: LocalMapBottomSheetAnchorKeys = {
  home: [
    {
      id: 'top',
      edge: 'top',
      offset: 10,
      scrollBehavior: 'scrollContent',
      sizingMode: 'fixed',
    },
    {
      id: 'medium',
      edge: 'top',
      offset: 10,
      scrollBehavior: 'scrollContent',
      sizingMode: 'fixed',
    },
    {
      id: 'bottom',
      edge: 'bottom',
      offset: 60,
      scrollBehavior: 'dragSheet',
      sizingMode: 'fixed',
    },
  ],
  search: [
    {
      id: 'top',
      edge: 'top',
      offset: MAP_TOP_AREA_SIZE_MAPPER.search - 1, // margin or error to prevent gap
      scrollBehavior: 'scrollContent',
      sizingMode: 'fixed',
    },
    {
      id: 'bottom',
      edge: 'bottom',
      offset: 64,
      scrollBehavior: 'dragSheet',
      sizingMode: 'fixed',
    },
  ],
  searchTyping: [
    {
      id: 'bottom',
      edge: 'bottom',
      offset: 0,
      scrollBehavior: 'dragSheet',
      sizingMode: 'fixed',
    },
  ],
}

const mediumHeightBottomSheetAnchorMapper: LocalMapBottomSheetAnchorKeys = {
  home: [
    {
      id: 'top',
      edge: 'top',
      offset: 72,
      scrollBehavior: 'scrollContent',
      sizingMode: 'fixed',
    },
    {
      id: 'bottom',
      edge: 'bottom',
      offset: 60,
      scrollBehavior: 'dragSheet',
      sizingMode: 'fixed',
    },
  ],
  search: [
    {
      id: 'top',
      edge: 'top',
      offset: MAP_TOP_AREA_SIZE_MAPPER.search - 1, // margin or error to prevent gap
      scrollBehavior: 'scrollContent',
      sizingMode: 'fixed',
    },
    {
      id: 'bottom',
      edge: 'bottom',
      offset: 64,
      scrollBehavior: 'dragSheet',
      sizingMode: 'fixed',
    },
  ],
  searchTyping: [
    {
      id: 'bottom',
      edge: 'bottom',
      offset: 0,
      scrollBehavior: 'dragSheet',
      sizingMode: 'fixed',
    },
  ],
}

const largeHeightBottomSheetAnchorMapper: LocalMapBottomSheetAnchorKeys = {
  home: [
    {
      id: 'top',
      edge: 'top',
      offset: 72,
      scrollBehavior: 'scrollContent',
      sizingMode: 'fixed',
    },
    {
      id: 'middle',
      edge: 'bottom',
      offset: MIDDLE_OFFSET,
      scrollBehavior: 'dragSheet',
      sizingMode: 'fixed',
    },
    {
      id: 'bottom',
      edge: 'bottom',
      offset: 60,
      scrollBehavior: 'dragSheet',
      sizingMode: 'fixed',
    },
  ],
  search: [
    {
      id: 'top',
      edge: 'top',
      offset: MAP_TOP_AREA_SIZE_MAPPER.search - 1, // margin or error to prevent gap
      scrollBehavior: 'scrollContent',
      sizingMode: 'fixed',
    },
    {
      id: 'middle',
      edge: 'bottom',
      offset: MIDDLE_OFFSET, // %로 변경시 getLocalMapBottomSheetAnchorInfo.offset as number 을 사용하는 곳 수정 필요
      scrollBehavior: 'dragSheet',
      sizingMode: 'fixed',
    },
    {
      id: 'bottom',
      edge: 'bottom',
      offset: 64,
      scrollBehavior: 'dragSheet',
      sizingMode: 'fixed',
    },
  ],
  searchTyping: [
    {
      id: 'bottom',
      edge: 'bottom',
      offset: 0,
      scrollBehavior: 'dragSheet',
      sizingMode: 'fixed',
    },
  ],
}

export type BottomSheetLandscapeInitialProps = {
  landsapeAnchors: LandscapeAnchors
  initialAnchorId?: string
}

export const BottomSheetAnchorMapper: LandscapeAnchors = {
  large: largeHeightBottomSheetAnchorMapper,
  medium: mediumHeightBottomSheetAnchorMapper,
  small: smallHeightBottomSheetAnchorMapper,
}

export const BottomSheetInitialAnchorIdMapper: Record<
  HeightViewType,
  Partial<{
    [key in LocalMapStepKeys]: string
  }>
> = {
  large: {
    home: 'middle',
    search: 'middle',
  },
  medium: {
    home: 'bottom',
    search: 'bottom',
  },
  small: {
    home: 'bottom',
    search: 'bottom',
  },
}

export const NormalizedBottomSheetAnchorMapper = HeightViewTypes.reduce<
  Record<HeightViewType, Record<string, Record<string, Anchor>>>
>(
  (acc, type) => ({
    ...acc,
    [type]: mapValues(BottomSheetAnchorMapper[type], (anchors) =>
      keyBy(anchors, 'id')
    ),
  }),
  {
    small: {},
    medium: {},
    large: {},
  }
)
