/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface DaangnsearchVerticalv1Image
 */
export interface DaangnsearchVerticalv1Image {
    /**
     * 
     * @type {string}
     * @memberof DaangnsearchVerticalv1Image
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DaangnsearchVerticalv1Image
     */
    filepath?: string;
    /**
     * 
     * @type {string}
     * @memberof DaangnsearchVerticalv1Image
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof DaangnsearchVerticalv1Image
     */
    service?: string;
    /**
     * 
     * @type {number}
     * @memberof DaangnsearchVerticalv1Image
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof DaangnsearchVerticalv1Image
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof DaangnsearchVerticalv1Image
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof DaangnsearchVerticalv1Image
     */
    thumbnail?: string;
    /**
     * 
     * @type {string}
     * @memberof DaangnsearchVerticalv1Image
     */
    medium?: string;
    /**
     * 
     * @type {number}
     * @memberof DaangnsearchVerticalv1Image
     */
    oldImageId?: number;
    /**
     * 
     * @type {string}
     * @memberof DaangnsearchVerticalv1Image
     */
    typename?: string;
}

/**
 * Check if a given object implements the DaangnsearchVerticalv1Image interface.
 */
export function instanceOfDaangnsearchVerticalv1Image(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DaangnsearchVerticalv1ImageFromJSON(json: any): DaangnsearchVerticalv1Image {
    return DaangnsearchVerticalv1ImageFromJSONTyped(json, false);
}

export function DaangnsearchVerticalv1ImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaangnsearchVerticalv1Image {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'filepath': !exists(json, 'filepath') ? undefined : json['filepath'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'thumbnail': !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
        'medium': !exists(json, 'medium') ? undefined : json['medium'],
        'oldImageId': !exists(json, 'oldImageId') ? undefined : json['oldImageId'],
        'typename': !exists(json, 'Typename') ? undefined : json['Typename'],
    };
}

export function DaangnsearchVerticalv1ImageToJSON(value?: DaangnsearchVerticalv1Image | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'filepath': value.filepath,
        'type': value.type,
        'service': value.service,
        'width': value.width,
        'height': value.height,
        'url': value.url,
        'thumbnail': value.thumbnail,
        'medium': value.medium,
        'oldImageId': value.oldImageId,
        'Typename': value.typename,
    };
}

