/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const V1ActionType = {
    Unspecified: 'ACTION_TYPE_UNSPECIFIED',
    HttpGetRequest: 'ACTION_TYPE_HTTP_GET_REQUEST',
    HttpPostRequest: 'ACTION_TYPE_HTTP_POST_REQUEST',
    PageTransition: 'ACTION_TYPE_PAGE_TRANSITION',
    ClaimCommerceCoupon: 'ACTION_TYPE_CLAIM_COMMERCE_COUPON',
    ClaimCommerceCouponWithFollowBusinessProfile: 'ACTION_TYPE_CLAIM_COMMERCE_COUPON_WITH_FOLLOW_BUSINESS_PROFILE',
    PageTransitionWithTargetUri: 'ACTION_TYPE_PAGE_TRANSITION_WITH_TARGET_URI'
} as const;
export type V1ActionType = typeof V1ActionType[keyof typeof V1ActionType];


export function V1ActionTypeFromJSON(json: any): V1ActionType {
    return V1ActionTypeFromJSONTyped(json, false);
}

export function V1ActionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ActionType {
    return json as V1ActionType;
}

export function V1ActionTypeToJSON(value?: V1ActionType | null): any {
    return value as any;
}

