/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1ActionTriggerType } from './V1ActionTriggerType';
import {
    V1ActionTriggerTypeFromJSON,
    V1ActionTriggerTypeFromJSONTyped,
    V1ActionTriggerTypeToJSON,
} from './V1ActionTriggerType';
import type { V1ActionType } from './V1ActionType';
import {
    V1ActionTypeFromJSON,
    V1ActionTypeFromJSONTyped,
    V1ActionTypeToJSON,
} from './V1ActionType';
import type { V1HttpGetRequestAction } from './V1HttpGetRequestAction';
import {
    V1HttpGetRequestActionFromJSON,
    V1HttpGetRequestActionFromJSONTyped,
    V1HttpGetRequestActionToJSON,
} from './V1HttpGetRequestAction';
import type { V1HttpPostRequestAction } from './V1HttpPostRequestAction';
import {
    V1HttpPostRequestActionFromJSON,
    V1HttpPostRequestActionFromJSONTyped,
    V1HttpPostRequestActionToJSON,
} from './V1HttpPostRequestAction';
import type { V1PageTransitionWithTargetURIAction } from './V1PageTransitionWithTargetURIAction';
import {
    V1PageTransitionWithTargetURIActionFromJSON,
    V1PageTransitionWithTargetURIActionFromJSONTyped,
    V1PageTransitionWithTargetURIActionToJSON,
} from './V1PageTransitionWithTargetURIAction';

/**
 * 
 * @export
 * @interface V1Action
 */
export interface V1Action {
    /**
     * 
     * @type {V1ActionType}
     * @memberof V1Action
     */
    type: V1ActionType;
    /**
     * 
     * @type {V1ActionTriggerType}
     * @memberof V1Action
     */
    triggerType: V1ActionTriggerType;
    /**
     * 
     * @type {V1HttpGetRequestAction}
     * @memberof V1Action
     */
    httpGetRequestAction?: V1HttpGetRequestAction;
    /**
     * 
     * @type {V1HttpPostRequestAction}
     * @memberof V1Action
     */
    httpPostRequestAction?: V1HttpPostRequestAction;
    /**
     * 
     * @type {V1PageTransitionWithTargetURIAction}
     * @memberof V1Action
     */
    pageTransitionWithTargetUriAction?: V1PageTransitionWithTargetURIAction;
}

/**
 * Check if a given object implements the V1Action interface.
 */
export function instanceOfV1Action(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "triggerType" in value;

    return isInstance;
}

export function V1ActionFromJSON(json: any): V1Action {
    return V1ActionFromJSONTyped(json, false);
}

export function V1ActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Action {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': V1ActionTypeFromJSON(json['type']),
        'triggerType': V1ActionTriggerTypeFromJSON(json['trigger_type']),
        'httpGetRequestAction': !exists(json, 'http_get_request_action') ? undefined : V1HttpGetRequestActionFromJSON(json['http_get_request_action']),
        'httpPostRequestAction': !exists(json, 'http_post_request_action') ? undefined : V1HttpPostRequestActionFromJSON(json['http_post_request_action']),
        'pageTransitionWithTargetUriAction': !exists(json, 'page_transition_with_target_uri_action') ? undefined : V1PageTransitionWithTargetURIActionFromJSON(json['page_transition_with_target_uri_action']),
    };
}

export function V1ActionToJSON(value?: V1Action | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': V1ActionTypeToJSON(value.type),
        'trigger_type': V1ActionTriggerTypeToJSON(value.triggerType),
        'http_get_request_action': V1HttpGetRequestActionToJSON(value.httpGetRequestAction),
        'http_post_request_action': V1HttpPostRequestActionToJSON(value.httpPostRequestAction),
        'page_transition_with_target_uri_action': V1PageTransitionWithTargetURIActionToJSON(value.pageTransitionWithTargetUriAction),
    };
}

