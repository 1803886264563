/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { PlaceDocumentFetch } from './PlaceDocumentFetch';
import {
    PlaceDocumentFetchFromJSON,
    PlaceDocumentFetchFromJSONTyped,
    PlaceDocumentFetchToJSON,
} from './PlaceDocumentFetch';
import type { PlaceDocumentMeta } from './PlaceDocumentMeta';
import {
    PlaceDocumentMetaFromJSON,
    PlaceDocumentMetaFromJSONTyped,
    PlaceDocumentMetaToJSON,
} from './PlaceDocumentMeta';
import type { PlaceDocumentSource } from './PlaceDocumentSource';
import {
    PlaceDocumentSourceFromJSON,
    PlaceDocumentSourceFromJSONTyped,
    PlaceDocumentSourceToJSON,
} from './PlaceDocumentSource';

/**
 * 
 * @export
 * @interface V1PlaceDocument
 */
export interface V1PlaceDocument {
    /**
     * 
     * @type {PlaceDocumentSource}
     * @memberof V1PlaceDocument
     */
    source: PlaceDocumentSource;
    /**
     * 
     * @type {PlaceDocumentFetch}
     * @memberof V1PlaceDocument
     */
    fetch: PlaceDocumentFetch;
    /**
     * 
     * @type {PlaceDocumentMeta}
     * @memberof V1PlaceDocument
     */
    meta: PlaceDocumentMeta;
}

/**
 * Check if a given object implements the V1PlaceDocument interface.
 */
export function instanceOfV1PlaceDocument(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "fetch" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function V1PlaceDocumentFromJSON(json: any): V1PlaceDocument {
    return V1PlaceDocumentFromJSONTyped(json, false);
}

export function V1PlaceDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PlaceDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': PlaceDocumentSourceFromJSON(json['source']),
        'fetch': PlaceDocumentFetchFromJSON(json['fetch']),
        'meta': PlaceDocumentMetaFromJSON(json['meta']),
    };
}

export function V1PlaceDocumentToJSON(value?: V1PlaceDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': PlaceDocumentSourceToJSON(value.source),
        'fetch': PlaceDocumentFetchToJSON(value.fetch),
        'meta': PlaceDocumentMetaToJSON(value.meta),
    };
}

