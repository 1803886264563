/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * Deprecated. use SearchCategorySuggestions.
 * @export
 * @interface V1SearchFilter
 */
export interface V1SearchFilter {
    /**
     * 
     * @type {string}
     * @memberof V1SearchFilter
     */
    referrerQuery: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1SearchFilter
     */
    keywords: Array<string>;
}

/**
 * Check if a given object implements the V1SearchFilter interface.
 */
export function instanceOfV1SearchFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "referrerQuery" in value;
    isInstance = isInstance && "keywords" in value;

    return isInstance;
}

export function V1SearchFilterFromJSON(json: any): V1SearchFilter {
    return V1SearchFilterFromJSONTyped(json, false);
}

export function V1SearchFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referrerQuery': json['referrer_query'],
        'keywords': json['keywords'],
    };
}

export function V1SearchFilterToJSON(value?: V1SearchFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referrer_query': value.referrerQuery,
        'keywords': value.keywords,
    };
}

