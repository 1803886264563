/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { Searchdomainv1Image } from './Searchdomainv1Image';
import {
    Searchdomainv1ImageFromJSON,
    Searchdomainv1ImageFromJSONTyped,
    Searchdomainv1ImageToJSON,
} from './Searchdomainv1Image';

/**
 * 
 * @export
 * @interface PlaceDocumentFetch
 */
export interface PlaceDocumentFetch {
    /**
     * 
     * @type {string}
     * @memberof PlaceDocumentFetch
     */
    regionName?: string;
    /**
     * 
     * @type {Searchdomainv1Image}
     * @memberof PlaceDocumentFetch
     */
    picture?: Searchdomainv1Image;
    /**
     * 
     * @type {Searchdomainv1Image}
     * @memberof PlaceDocumentFetch
     */
    bgImage?: Searchdomainv1Image;
    /**
     * 
     * @type {boolean}
     * @memberof PlaceDocumentFetch
     */
    hasCoupon?: boolean;
}

/**
 * Check if a given object implements the PlaceDocumentFetch interface.
 */
export function instanceOfPlaceDocumentFetch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlaceDocumentFetchFromJSON(json: any): PlaceDocumentFetch {
    return PlaceDocumentFetchFromJSONTyped(json, false);
}

export function PlaceDocumentFetchFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceDocumentFetch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regionName': !exists(json, 'regionName') ? undefined : json['regionName'],
        'picture': !exists(json, 'picture') ? undefined : Searchdomainv1ImageFromJSON(json['picture']),
        'bgImage': !exists(json, 'bgImage') ? undefined : Searchdomainv1ImageFromJSON(json['bgImage']),
        'hasCoupon': !exists(json, 'hasCoupon') ? undefined : json['hasCoupon'],
    };
}

export function PlaceDocumentFetchToJSON(value?: PlaceDocumentFetch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'regionName': value.regionName,
        'picture': Searchdomainv1ImageToJSON(value.picture),
        'bgImage': Searchdomainv1ImageToJSON(value.bgImage),
        'hasCoupon': value.hasCoupon,
    };
}

