import { AppScreen } from '@stackflow/plugin-basic-ui'
import classNames from 'classnames'

import { clientEnvironment } from '@src/bridge/utils'
import { FeedStepKeys } from '@src/hooks/useStepRouter'
import LocalMapBottomSheetSkeleton from '@src/local-map-utils/local-map-bottomsheet/LocalMapBottomSheetSkeleton'
import { useQueryParams } from '@src/react-utils/url/useQueryParams'

import * as css from './index.css'
import * as zIndexCss from './zIndex.css'
import FeedRecommendPostSkeleton from '../home-feed-step/feed-view-type/sections/feed-recommend-post/FeedRecommendPostSkeleton'
import SearchInputSkeleton from '../home-search-step/search-input/SearchInputSkeleton'
import SearchRecommendationChipsSkeleton from '../home-search-step/search-recommendation-chip/SearchRecommendationChipsSkeleton'

const HomeSkeleton = () => {
  const { stepType: stepTypeParam } = useQueryParams<{
    stepType: FeedStepKeys
  }>()
  const stepType =
    stepTypeParam && FeedStepKeys.includes(stepTypeParam)
      ? stepTypeParam
      : 'home'

  // iOS 초기 1회 덜그덕거리는 렌더링 수정
  if (
    clientEnvironment.isIOS &&
    !localStorage.getItem('cached-safe-area-inset-top')
  ) {
    return null
  }

  return (
    <AppScreen>
      <div className={classNames(zIndexCss.zIndexContainer, css.skeletonMap)}>
        <SearchInputSkeleton />
        <SearchRecommendationChipsSkeleton chipCount={5} />
        <LocalMapBottomSheetSkeleton stepType={stepType}>
          {stepType === 'home' && <FeedRecommendPostSkeleton />}
        </LocalMapBottomSheetSkeleton>
      </div>
    </AppScreen>
  )
}

export default HomeSkeleton
