/**
 * 스테이지
 */
export const STAGE = import.meta.env.VITE_STAGE as
  | 'production'
  | 'alpha'
  | 'development'

/**
 * 디바이스 정보
 */
export const IS_ANDROID = /Android/i.test(navigator.userAgent)

/**
 * 커밋 Hash
 */
export const CLIENT_VERSION = import.meta.env.VITE_VERSION ?? ''

export const IS_PRODUCTION = STAGE === 'production'
export const IS_LOCAL = import.meta.env.VITE_IS_LOCAL === 'true'

export const DATADOG_CLIENT_TOKEN =
  import.meta.env.VITE_DATADOG_CLIENT_TOKEN ?? ''
export const DATADOG_APPLICATION_ID =
  import.meta.env.VITE_DATADOG_APPLICATION_ID ?? ''

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN ?? ''
export const SENTRY_AUTH_TOKEN = import.meta.env.VITE_SENTRY_AUTH_TOKEN ?? ''

export const CLARITY_ENABLED = import.meta.env.VITE_CLARITY_ENABLED === 'true'

/**
 * 외부 api url
 */
export const EXPERTS_WEBVIEW_BASE_URL = IS_PRODUCTION
  ? 'https://yongdal.karrotwebview.com'
  : 'https://yongdal.alpha.karrotwebview.com'

export const BIZ_PROFILE_WEB_BASE_URL = IS_PRODUCTION
  ? 'https://bizprofile.karrotwebview.com'
  : 'https://bizprofile.alpha.karrotwebview.com'

export const PLACE_WEB_BASE_URL = IS_PRODUCTION
  ? 'https://place.karrotwebview.com'
  : 'https://place.alpha.karrotwebview.com'

export const LOCAL_MAP_WEB_BASE_URL = IS_PRODUCTION
  ? 'https://local-map.karrotwebview.com'
  : 'https://local-map.alpha.karrotwebview.com'

export const NEARBY_WEB_BASE_URL = IS_PRODUCTION
  ? 'https://nearby.karrotwebview.com'
  : 'https://nearby.alpha.karrotwebview.com'
