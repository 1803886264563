/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1Action } from './V1Action';
import {
    V1ActionFromJSON,
    V1ActionFromJSONTyped,
    V1ActionToJSON,
} from './V1Action';
import type { V1CalloutVariant } from './V1CalloutVariant';
import {
    V1CalloutVariantFromJSON,
    V1CalloutVariantFromJSONTyped,
    V1CalloutVariantToJSON,
} from './V1CalloutVariant';

/**
 * 
 * @export
 * @interface V1ViewTypeActionableCallout
 */
export interface V1ViewTypeActionableCallout {
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeActionableCallout
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeActionableCallout
     */
    description: string;
    /**
     * 
     * @type {V1CalloutVariant}
     * @memberof V1ViewTypeActionableCallout
     */
    variant: V1CalloutVariant;
    /**
     * 
     * @type {Array<V1Action>}
     * @memberof V1ViewTypeActionableCallout
     */
    actions: Array<V1Action>;
}

/**
 * Check if a given object implements the V1ViewTypeActionableCallout interface.
 */
export function instanceOfV1ViewTypeActionableCallout(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "variant" in value;
    isInstance = isInstance && "actions" in value;

    return isInstance;
}

export function V1ViewTypeActionableCalloutFromJSON(json: any): V1ViewTypeActionableCallout {
    return V1ViewTypeActionableCalloutFromJSONTyped(json, false);
}

export function V1ViewTypeActionableCalloutFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ViewTypeActionableCallout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': json['description'],
        'variant': V1CalloutVariantFromJSON(json['variant']),
        'actions': ((json['actions'] as Array<any>).map(V1ActionFromJSON)),
    };
}

export function V1ViewTypeActionableCalloutToJSON(value?: V1ViewTypeActionableCallout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'variant': V1CalloutVariantToJSON(value.variant),
        'actions': ((value.actions as Array<any>).map(V1ActionToJSON)),
    };
}

