/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1Action } from './V1Action';
import {
    V1ActionFromJSON,
    V1ActionFromJSONTyped,
    V1ActionToJSON,
} from './V1Action';
import type { V1AutoCompleteDomainType } from './V1AutoCompleteDomainType';
import {
    V1AutoCompleteDomainTypeFromJSON,
    V1AutoCompleteDomainTypeFromJSONTyped,
    V1AutoCompleteDomainTypeToJSON,
} from './V1AutoCompleteDomainType';
import type { V1AutoCompleteKeyword } from './V1AutoCompleteKeyword';
import {
    V1AutoCompleteKeywordFromJSON,
    V1AutoCompleteKeywordFromJSONTyped,
    V1AutoCompleteKeywordToJSON,
} from './V1AutoCompleteKeyword';
import type { V1PlaceDocument } from './V1PlaceDocument';
import {
    V1PlaceDocumentFromJSON,
    V1PlaceDocumentFromJSONTyped,
    V1PlaceDocumentToJSON,
} from './V1PlaceDocument';

/**
 * 
 * @export
 * @interface V1AutoComplete
 */
export interface V1AutoComplete {
    /**
     * 
     * @type {V1AutoCompleteKeyword}
     * @memberof V1AutoComplete
     */
    keyword?: V1AutoCompleteKeyword;
    /**
     * 
     * @type {V1PlaceDocument}
     * @memberof V1AutoComplete
     */
    placeDocument?: V1PlaceDocument;
    /**
     * 
     * @type {V1AutoCompleteDomainType}
     * @memberof V1AutoComplete
     */
    domainType: V1AutoCompleteDomainType;
    /**
     * 
     * @type {Array<V1Action>}
     * @memberof V1AutoComplete
     */
    actions: Array<V1Action>;
}

/**
 * Check if a given object implements the V1AutoComplete interface.
 */
export function instanceOfV1AutoComplete(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domainType" in value;
    isInstance = isInstance && "actions" in value;

    return isInstance;
}

export function V1AutoCompleteFromJSON(json: any): V1AutoComplete {
    return V1AutoCompleteFromJSONTyped(json, false);
}

export function V1AutoCompleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1AutoComplete {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keyword': !exists(json, 'keyword') ? undefined : V1AutoCompleteKeywordFromJSON(json['keyword']),
        'placeDocument': !exists(json, 'place_document') ? undefined : V1PlaceDocumentFromJSON(json['place_document']),
        'domainType': V1AutoCompleteDomainTypeFromJSON(json['domain_type']),
        'actions': ((json['actions'] as Array<any>).map(V1ActionFromJSON)),
    };
}

export function V1AutoCompleteToJSON(value?: V1AutoComplete | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keyword': V1AutoCompleteKeywordToJSON(value.keyword),
        'place_document': V1PlaceDocumentToJSON(value.placeDocument),
        'domain_type': V1AutoCompleteDomainTypeToJSON(value.domainType),
        'actions': ((value.actions as Array<any>).map(V1ActionToJSON)),
    };
}

