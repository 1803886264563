/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { TypeLatLng } from './TypeLatLng';
import {
    TypeLatLngFromJSON,
    TypeLatLngFromJSONTyped,
    TypeLatLngToJSON,
} from './TypeLatLng';
import type { V1PlaceType } from './V1PlaceType';
import {
    V1PlaceTypeFromJSON,
    V1PlaceTypeFromJSONTyped,
    V1PlaceTypeToJSON,
} from './V1PlaceType';

/**
 * 유저의 위치, 장소 정보를 담고있어요.
 * @export
 * @interface V1UserLocation
 */
export interface V1UserLocation {
    /**
     * 
     * @type {string}
     * @memberof V1UserLocation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof V1UserLocation
     */
    fleamarketUserId: string;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1UserLocation
     */
    point: TypeLatLng;
    /**
     * 
     * @type {V1PlaceType}
     * @memberof V1UserLocation
     */
    place: V1PlaceType;
    /**
     * 
     * @type {Date}
     * @memberof V1UserLocation
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof V1UserLocation
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof V1UserLocation
     */
    readonly deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof V1UserLocation
     */
    readonly encryptedId?: string;
}

/**
 * Check if a given object implements the V1UserLocation interface.
 */
export function instanceOfV1UserLocation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fleamarketUserId" in value;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "place" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function V1UserLocationFromJSON(json: any): V1UserLocation {
    return V1UserLocationFromJSONTyped(json, false);
}

export function V1UserLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1UserLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fleamarketUserId': json['fleamarketUserId'],
        'point': TypeLatLngFromJSON(json['point']),
        'place': V1PlaceTypeFromJSON(json['place']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'encryptedId': !exists(json, 'encryptedId') ? undefined : json['encryptedId'],
    };
}

export function V1UserLocationToJSON(value?: V1UserLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fleamarketUserId': value.fleamarketUserId,
        'point': TypeLatLngToJSON(value.point),
        'place': V1PlaceTypeToJSON(value.place),
    };
}

