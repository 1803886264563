/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { TypeLatLng } from './TypeLatLng';
import {
    TypeLatLngFromJSON,
    TypeLatLngFromJSONTyped,
    TypeLatLngToJSON,
} from './TypeLatLng';
import type { V1PlaceType } from './V1PlaceType';
import {
    V1PlaceTypeFromJSON,
    V1PlaceTypeFromJSONTyped,
    V1PlaceTypeToJSON,
} from './V1PlaceType';

/**
 * 
 * @export
 * @interface V1CreateUserLocationRequest
 */
export interface V1CreateUserLocationRequest {
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1CreateUserLocationRequest
     */
    point: TypeLatLng;
    /**
     * 
     * @type {V1PlaceType}
     * @memberof V1CreateUserLocationRequest
     */
    place: V1PlaceType;
}

/**
 * Check if a given object implements the V1CreateUserLocationRequest interface.
 */
export function instanceOfV1CreateUserLocationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "place" in value;

    return isInstance;
}

export function V1CreateUserLocationRequestFromJSON(json: any): V1CreateUserLocationRequest {
    return V1CreateUserLocationRequestFromJSONTyped(json, false);
}

export function V1CreateUserLocationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1CreateUserLocationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'point': TypeLatLngFromJSON(json['point']),
        'place': V1PlaceTypeFromJSON(json['place']),
    };
}

export function V1CreateUserLocationRequestToJSON(value?: V1CreateUserLocationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'point': TypeLatLngToJSON(value.point),
        'place': V1PlaceTypeToJSON(value.place),
    };
}

