import { Skeleton } from '@src/react-utils/skeleton'

import * as css from './SearchInput.css'

const SearchInputSkeleton = () => {
  const styleType = 'transparent'

  return (
    <div
      className={css.container({
        styleType,
      })}
    >
      <Skeleton width="100%" height="100%" />
    </div>
  )
}

export default SearchInputSkeleton
