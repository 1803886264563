// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { DaangnsearchVerticalv1BusinessDays } from './DaangnsearchVerticalv1BusinessDays';
import {
    DaangnsearchVerticalv1BusinessDaysFromJSON,
    DaangnsearchVerticalv1BusinessDaysFromJSONTyped,
    DaangnsearchVerticalv1BusinessDaysToJSON,
} from './DaangnsearchVerticalv1BusinessDays';
import type { DaangnsearchVerticalv1BusinessOperation } from './DaangnsearchVerticalv1BusinessOperation';
import {
    DaangnsearchVerticalv1BusinessOperationFromJSON,
    DaangnsearchVerticalv1BusinessOperationFromJSONTyped,
    DaangnsearchVerticalv1BusinessOperationToJSON,
} from './DaangnsearchVerticalv1BusinessOperation';
import type { DaangnsearchVerticalv1Distance } from './DaangnsearchVerticalv1Distance';
import {
    DaangnsearchVerticalv1DistanceFromJSON,
    DaangnsearchVerticalv1DistanceFromJSONTyped,
    DaangnsearchVerticalv1DistanceToJSON,
} from './DaangnsearchVerticalv1Distance';
import type { DaangnsearchVerticalv1HolidayType } from './DaangnsearchVerticalv1HolidayType';
import {
    DaangnsearchVerticalv1HolidayTypeFromJSON,
    DaangnsearchVerticalv1HolidayTypeFromJSONTyped,
    DaangnsearchVerticalv1HolidayTypeToJSON,
} from './DaangnsearchVerticalv1HolidayType';
import type { DaangnsearchVerticalv1Image } from './DaangnsearchVerticalv1Image';
import {
    DaangnsearchVerticalv1ImageFromJSON,
    DaangnsearchVerticalv1ImageFromJSONTyped,
    DaangnsearchVerticalv1ImageToJSON,
} from './DaangnsearchVerticalv1Image';

/**
 *
 * @export
 * @interface V1PlaceDoc
 */
export interface V1PlaceDoc {
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    docId?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    docToken?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    regionId?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    regionName?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    categoryId?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    categoryText?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    pictureId?: string;
    /**
     *
     * @type {DaangnsearchVerticalv1Image}
     * @memberof V1PlaceDoc
     */
    picture?: DaangnsearchVerticalv1Image;
    /**
     *
     * @type {DaangnsearchVerticalv1Image}
     * @memberof V1PlaceDoc
     */
    bgImage?: DaangnsearchVerticalv1Image;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    bgImageIds?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    location?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    collectionName?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof V1PlaceDoc
     */
    score?: number;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    followsCount?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    reviewCount?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    displayCategoryName?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    address?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    jibunAddress?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    addressExtra?: string;
    /**
     *
     * @type {boolean}
     * @memberof V1PlaceDoc
     */
    storeOnSale?: boolean;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    brandBaId?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    poiTaggedCount?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    totalReviewCount?: string;
    /**
     *
     * @type {boolean}
     * @memberof V1PlaceDoc
     */
    reservable?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof V1PlaceDoc
     */
    hasCoupon?: boolean;
    /**
     *
     * @type {DaangnsearchVerticalv1BusinessDays}
     * @memberof V1PlaceDoc
     */
    businessDays?: DaangnsearchVerticalv1BusinessDays;
    /**
     *
     * @type {DaangnsearchVerticalv1HolidayType}
     * @memberof V1PlaceDoc
     */
    holidayType?: DaangnsearchVerticalv1HolidayType;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    businessDaysExtra?: string;
    /**
     *
     * @type {DaangnsearchVerticalv1BusinessOperation}
     * @memberof V1PlaceDoc
     */
    businessOperation?: DaangnsearchVerticalv1BusinessOperation;
    /**
     *
     * @type {DaangnsearchVerticalv1Distance}
     * @memberof V1PlaceDoc
     */
    distance?: DaangnsearchVerticalv1Distance;
    /**
     *
     * @type {Array<number>}
     * @memberof V1PlaceDoc
     */
    themeIds?: Array<number>;
    /**
     *
     * @type {Date}
     * @memberof V1PlaceDoc
     */
    createdAt?: Date;
    /**
     *
     * @type {Date}
     * @memberof V1PlaceDoc
     */
    updatedAt?: Date;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    explanationJsonStr?: string;
    /**
     *
     * @type {string}
     * @memberof V1PlaceDoc
     */
    termVectorJsonStr?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof V1PlaceDoc
     */
    matchedQueries?: Array<string>;
}

/**
 * Check if a given object implements the V1PlaceDoc interface.
 */
export function instanceOfV1PlaceDoc(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function V1PlaceDocFromJSON(json: any): V1PlaceDoc {
    return V1PlaceDocFromJSONTyped(json, false);
}

export function V1PlaceDocFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PlaceDoc {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'docId': !exists(json, 'doc_id') ? undefined : json['doc_id'],
        'docToken': !exists(json, 'doc_token') ? undefined : json['doc_token'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'regionId': !exists(json, 'region_id') ? undefined : json['region_id'],
        'regionName': !exists(json, 'region_name') ? undefined : json['region_name'],
        'categoryId': !exists(json, 'category_id') ? undefined : json['category_id'],
        'categoryText': !exists(json, 'category_text') ? undefined : json['category_text'],
        'pictureId': !exists(json, 'picture_id') ? undefined : json['picture_id'],
        'picture': !exists(json, 'picture') ? undefined : DaangnsearchVerticalv1ImageFromJSON(json['picture']),
        'bgImage': !exists(json, 'bg_image') ? undefined : DaangnsearchVerticalv1ImageFromJSON(json['bg_image']),
        'bgImageIds': !exists(json, 'bg_image_ids') ? undefined : json['bg_image_ids'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'collectionName': !exists(json, 'collection_name') ? undefined : json['collection_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'followsCount': !exists(json, 'follows_count') ? undefined : json['follows_count'],
        'reviewCount': !exists(json, 'review_count') ? undefined : json['review_count'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'displayCategoryName': !exists(json, 'display_category_name') ? undefined : json['display_category_name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'jibunAddress': !exists(json, 'jibun_address') ? undefined : json['jibun_address'],
        'addressExtra': !exists(json, 'address_extra') ? undefined : json['address_extra'],
        'storeOnSale': !exists(json, 'store_on_sale') ? undefined : json['store_on_sale'],
        'brandBaId': !exists(json, 'brand_ba_id') ? undefined : json['brand_ba_id'],
        'poiTaggedCount': !exists(json, 'poi_tagged_count') ? undefined : json['poi_tagged_count'],
        'totalReviewCount': !exists(json, 'total_review_count') ? undefined : json['total_review_count'],
        'reservable': !exists(json, 'reservable') ? undefined : json['reservable'],
        'hasCoupon': !exists(json, 'has_coupon') ? undefined : json['has_coupon'],
        'businessDays': !exists(json, 'business_days') ? undefined : DaangnsearchVerticalv1BusinessDaysFromJSON(json['business_days']),
        'holidayType': !exists(json, 'holiday_type') ? undefined : DaangnsearchVerticalv1HolidayTypeFromJSON(json['holiday_type']),
        'businessDaysExtra': !exists(json, 'business_days_extra') ? undefined : json['business_days_extra'],
        'businessOperation': !exists(json, 'business_operation') ? undefined : DaangnsearchVerticalv1BusinessOperationFromJSON(json['business_operation']),
        'distance': !exists(json, 'distance') ? undefined : DaangnsearchVerticalv1DistanceFromJSON(json['distance']),
        'themeIds': !exists(json, 'theme_ids') ? undefined : json['theme_ids'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'explanationJsonStr': !exists(json, 'explanation_json_str') ? undefined : json['explanation_json_str'],
        'termVectorJsonStr': !exists(json, 'term_vector_json_str') ? undefined : json['term_vector_json_str'],
        'matchedQueries': !exists(json, 'matched_queries') ? undefined : json['matched_queries'],
    };
}

export function V1PlaceDocToJSON(value?: V1PlaceDoc | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'docId': value.docId,
        'docToken': value.docToken,
        'id': value.id,
        'regionId': value.regionId,
        'regionName': value.regionName,
        'categoryId': value.categoryId,
        'categoryText': value.categoryText,
        'pictureId': value.pictureId,
        'picture': DaangnsearchVerticalv1ImageToJSON(value.picture),
        'bgImage': DaangnsearchVerticalv1ImageToJSON(value.bgImage),
        'bgImageIds': value.bgImageIds,
        'location': value.location,
        'collectionName': value.collectionName,
        'name': value.name,
        'score': value.score,
        'followsCount': value.followsCount,
        'reviewCount': value.reviewCount,
        'description': value.description,
        'displayCategoryName': value.displayCategoryName,
        'address': value.address,
        'jibunAddress': value.jibunAddress,
        'addressExtra': value.addressExtra,
        'storeOnSale': value.storeOnSale,
        'brandBaId': value.brandBaId,
        'poiTaggedCount': value.poiTaggedCount,
        'totalReviewCount': value.totalReviewCount,
        'reservable': value.reservable,
        'hasCoupon': value.hasCoupon,
        'businessDays': DaangnsearchVerticalv1BusinessDaysToJSON(value.businessDays),
        'holidayType': DaangnsearchVerticalv1HolidayTypeToJSON(value.holidayType),
        'businessDaysExtra': value.businessDaysExtra,
        'businessOperation': DaangnsearchVerticalv1BusinessOperationToJSON(value.businessOperation),
        'distance': DaangnsearchVerticalv1DistanceToJSON(value.distance),
        'themeIds': value.themeIds,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'explanationJsonStr': value.explanationJsonStr,
        'termVectorJsonStr': value.termVectorJsonStr,
        'matchedQueries': value.matchedQueries,
    };
}
