/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { DaangnsearchVerticalv1BusinessHours } from './DaangnsearchVerticalv1BusinessHours';
import {
    DaangnsearchVerticalv1BusinessHoursFromJSON,
    DaangnsearchVerticalv1BusinessHoursFromJSONTyped,
    DaangnsearchVerticalv1BusinessHoursToJSON,
} from './DaangnsearchVerticalv1BusinessHours';

/**
 * 
 * @export
 * @interface DaangnsearchVerticalv1BusinessDay
 */
export interface DaangnsearchVerticalv1BusinessDay {
    /**
     * 
     * @type {DaangnsearchVerticalv1BusinessHours}
     * @memberof DaangnsearchVerticalv1BusinessDay
     */
    hours?: DaangnsearchVerticalv1BusinessHours;
    /**
     * 
     * @type {DaangnsearchVerticalv1BusinessHours}
     * @memberof DaangnsearchVerticalv1BusinessDay
     */
    breakHours?: DaangnsearchVerticalv1BusinessHours;
    /**
     * 
     * @type {boolean}
     * @memberof DaangnsearchVerticalv1BusinessDay
     */
    closed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DaangnsearchVerticalv1BusinessDay
     */
    open24hours?: boolean;
}

/**
 * Check if a given object implements the DaangnsearchVerticalv1BusinessDay interface.
 */
export function instanceOfDaangnsearchVerticalv1BusinessDay(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DaangnsearchVerticalv1BusinessDayFromJSON(json: any): DaangnsearchVerticalv1BusinessDay {
    return DaangnsearchVerticalv1BusinessDayFromJSONTyped(json, false);
}

export function DaangnsearchVerticalv1BusinessDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaangnsearchVerticalv1BusinessDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hours': !exists(json, 'hours') ? undefined : DaangnsearchVerticalv1BusinessHoursFromJSON(json['hours']),
        'breakHours': !exists(json, 'breakHours') ? undefined : DaangnsearchVerticalv1BusinessHoursFromJSON(json['breakHours']),
        'closed': !exists(json, 'closed') ? undefined : json['closed'],
        'open24hours': !exists(json, 'open24hours') ? undefined : json['open24hours'],
    };
}

export function DaangnsearchVerticalv1BusinessDayToJSON(value?: DaangnsearchVerticalv1BusinessDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hours': DaangnsearchVerticalv1BusinessHoursToJSON(value.hours),
        'breakHours': DaangnsearchVerticalv1BusinessHoursToJSON(value.breakHours),
        'closed': value.closed,
        'open24hours': value.open24hours,
    };
}

