/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { Searchdomainv1BusinessHours } from './Searchdomainv1BusinessHours';
import {
    Searchdomainv1BusinessHoursFromJSON,
    Searchdomainv1BusinessHoursFromJSONTyped,
    Searchdomainv1BusinessHoursToJSON,
} from './Searchdomainv1BusinessHours';

/**
 * 
 * @export
 * @interface Searchdomainv1BusinessDay
 */
export interface Searchdomainv1BusinessDay {
    /**
     * 
     * @type {Searchdomainv1BusinessHours}
     * @memberof Searchdomainv1BusinessDay
     */
    hours: Searchdomainv1BusinessHours;
    /**
     * 
     * @type {Searchdomainv1BusinessHours}
     * @memberof Searchdomainv1BusinessDay
     */
    breakHours: Searchdomainv1BusinessHours;
    /**
     * 
     * @type {boolean}
     * @memberof Searchdomainv1BusinessDay
     */
    closed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Searchdomainv1BusinessDay
     */
    open24hours: boolean;
}

/**
 * Check if a given object implements the Searchdomainv1BusinessDay interface.
 */
export function instanceOfSearchdomainv1BusinessDay(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "hours" in value;
    isInstance = isInstance && "breakHours" in value;
    isInstance = isInstance && "closed" in value;
    isInstance = isInstance && "open24hours" in value;

    return isInstance;
}

export function Searchdomainv1BusinessDayFromJSON(json: any): Searchdomainv1BusinessDay {
    return Searchdomainv1BusinessDayFromJSONTyped(json, false);
}

export function Searchdomainv1BusinessDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): Searchdomainv1BusinessDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hours': Searchdomainv1BusinessHoursFromJSON(json['hours']),
        'breakHours': Searchdomainv1BusinessHoursFromJSON(json['breakHours']),
        'closed': json['closed'],
        'open24hours': json['open24hours'],
    };
}

export function Searchdomainv1BusinessDayToJSON(value?: Searchdomainv1BusinessDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hours': Searchdomainv1BusinessHoursToJSON(value.hours),
        'breakHours': Searchdomainv1BusinessHoursToJSON(value.breakHours),
        'closed': value.closed,
        'open24hours': value.open24hours,
    };
}

